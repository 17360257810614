import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import Navbar from "../Navbar";
import HomeSlider from '../HomeSlider';
import "./Home.css";
import Testimonials from '../Testimonials';
import Footer from "../Contact";
import "bootstrap/dist/css/bootstrap.min.css";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import HomeCard from '../HomeCard';
import HomeCardSpecialities from "../HomeCardSpecialities";
import TrendingLeisure from "../TrendingLeisure";
import TrendingWildlife from "../TrendingWildlife";

const destinations = [
  {
    name: "Kenya",
    images: ["https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Home%2Fsutirta-budiman-kjOBqwMUnWw-unsplash.jpg?alt=media&token=acfb900b-69ef-4055-b573-8645ea6c15f9"],
    description: "Kenya is a country of dramatic extremes and one of the most diverse places on Earth. Its open plains and dense forests, snow-capped mountains and deserts, ancient vibrant culture, and bustling modern life, along with its freshwater lakes and coral reefs, create a unique landscape found nowhere else in the world. Kenya is renowned for its savannah safaris and is often referred to as the 'Mecca of Wildlife.",
    link: "/kenya",
    buttonText: "Explore Kenya's Wildlife Adventures"
  },
  {
    name: "Vietnam",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Home%2Fbridge.jpg?alt=media&token=8bc0f132-8cbe-4c36-bcac-d69055769831"
    ],
    description: "With its rich history, vibrant culture, and breathtaking landscapes, Vietnam is one of the most popular holiday destinations in the world today. Vietnam can be visited year-round, as the weather across the country is suitable for various seasons. It is also one of the most tourist-friendly places and very affordable compared to many other countries. Vietnam is an excellent holiday destination for friends and families, offering a wide range of activities for different age groups.",
    link: "/vietnam",
    buttonText: "Discover the Beauty of Vietnam"
  },
  {
    name: "Tanzania",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2Ftanzania.avif?alt=media&token=dea821f8-7080-403b-ab6d-4eed631aa15d",
    ],
    description:"Known for its vast wilderness, this is one of the most sought-after wildlife destinations in the world. It is home to Africa’s highest mountain, Mount Kilimanjaro, and is famously known as the 'Land of Safaris.' Millions of wildebeests and other ungulates traverse this land during the Great Migration, crossing into Maasai Mara every year. During February, on the plains of Ndutu, these animals give birth to millions of calves, a truly spectacular sight to witness.",
    link: "/tanzania",
    buttonText: "Experience the Serengeti in Tanzania"
  },
  {
    name: "Kerala",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/packageTemplate%2FGems%20of%20%20Kerala.jpg?alt=media&token=504d0bc2-dd47-401b-b902-f62fc1a10b61",
    ],
    description:"Known for its serene backwaters, lush landscapes, and beautiful beaches, Kerala offers a peaceful escape. Experience houseboat rides in Alleppey, the misty tea gardens of Munnar, and the cultural richness of its temples and festivals. The state's vibrant wildlife sanctuaries and Ayurvedic retreats also provide a rejuvenating experience for nature and wellness enthusiasts.",
    link: "/kerala",
    buttonText: "Explore Kerala's Beauty"
  },
  {
    name: "Ranthambore",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Home%2FTiger-Bigcats-Wildsafari.jpg?alt=media&token=00ec7ab2-683d-4b9d-a331-30eccd0653e0"
    ],
    description:"Famously known as the land of the Royal Bengal Tigers, this place has a long history of man-animal coexistence. Ranthambore National Park is one of the largest and most renowned national parks in Northern India. The park is located in the Sawai Madhopur district of southeastern Rajasthan, about 130 km from Jaipur. Once a famous hunting ground for the Maharajas of Jaipur, today Ranthambore's terrain is a major wildlife tourist attraction, drawing the attention of many wildlife photographers and nature lovers to this destination.",
    link: "/ranthambore",
    buttonText: "Safari in Ranthambore National Park"
  },
  {
    name: "Himachal Pradesh",
    images: [
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Home%2Fhimachal-3647248_1920.jpg?alt=media&token=55a05050-e090-402c-8aa4-d8912a081fcf"    ],
    description:"A scenic Himalayan destination, Himachal Pradesh is perfect for adventure and nature lovers. Explore the charming hill towns of Shimla and Manali, trek through lush valleys, or enjoy spiritual retreats in places like Dharamshala and Spiti Valley. The state also offers thrilling activities like paragliding in Bir, river rafting in Kullu, and skiing in Solang Valley, making it an ideal getaway for thrill-seekers and peace lovers alike.",
    link: "/himachal",
    buttonText: "Escape to Himachal's Serenity"
  },
  {
    name: "Bandhavgarh",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2Fbandhavgarh.avif?alt=media&token=d4ac7304-ed95-494f-bebd-73aeaad341de",
    ],
    description:"Ever wondered about going on jungle drives in the 'Tiger Capital of the World'? We make that possible while ensuring your comfort both on and off the field. Tiger sightings here are more frequent and regular compared to any other tiger reserve in India, making a jungle safari here the perfect choice. With tigers in all zones, it promises to be a once-in-a-lifetime experience in the wild.",
    link: "/bandhavgarh",
    buttonText: "Explore Bandhavgarh's Tiger Reserve"
  },
  {
    name: "Iceland",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/packageTemplate%2FVik%20Iceland.jpg?alt=media&token=5d718077-ceb3-4baf-b9e3-eb4614b65d14"    
    ],
    description:"Iceland, the land of fire and ice, offers some of the most dramatic landscapes on Earth. From active volcanoes and geysers to vast glaciers and stunning waterfalls, Iceland's natural beauty is unmatched. Visitors can explore the Golden Circle, witness the northern lights, or relax in the geothermal Blue Lagoon. The island also boasts vibrant culture and history, with a strong connection to Norse mythology. It's a perfect destination for adventure seekers and nature lovers alike.",
    link: "/iceland",
    buttonText: "Visit Iceland"
  },
  {
    name: "Corbett",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Home%2FTuskers%20from%20kenya-Wildlife%20safari%20(1%20of%201).jpg?alt=media&token=37f2fcf2-0b00-429a-a305-bc27c00155c8"
    ],
    description:"Named after the famed hunter-turned-conservationist, Sir Edward Jim Corbett, this beautiful park lies in the lower Himalayas. The life-giving Ramganga River flows through this pristine jungle. Corbett Tiger Reserve is home to the magnificent Royal Bengal Tiger and hundreds of Asiatic elephants, which descend to the Dhikala grasslands during the summer. Visitors can also spot spotted deer, sambar, and the elusive hog deer. This is one of the best parks to experience Indian wildlife.",
    link: "/corbett",
    buttonText: "Visit Jim Corbett National Park"
  },
  {
    name: "Andaman",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/packageTemplate%2Fsea-326986_1280.jpg?alt=media&token=56ea269a-fda8-4e0c-a3ba-6b2aaac84c79",
    ],
    description:"The Andaman Islands, located in the Bay of Bengal, are a tropical paradise with pristine white-sand beaches, crystal-clear waters, and vibrant coral reefs. Known for their rich marine biodiversity, the islands offer excellent opportunities for snorkeling, scuba diving, and water sports. Beyond the beaches, visitors can explore lush rainforests, historic sites like the Cellular Jail, and unique indigenous cultures. The Andaman Islands provide a perfect blend of adventure and relaxation for beach lovers and explorers.",
    link: "/andaman",
    buttonText: "Explore Andaman"
  },
  {
    name: "Bandipur",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2Fbandipur.avif?alt=media&token=4d754254-8dc7-474e-b7c0-5c6289f0814e",
    ],
    description:"This beautiful reserve is located in Karnataka and is part of the Nilgiri Biosphere Reserve. It connects to Mudumalai and Wayanad National Parks, forming an extended part of the Nilgiri Biosphere. The park is blessed with lush greenery and offers incredible wildlife sightings. It is renowned for its tiger population, elephants, gaurs, and the diverse flora and fauna that thrive within its boundaries.",
    link: "/bandipur",
    buttonText: "Discover Bandipur's Wildlife"
  },
  {
    name:"Kabini",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2Fkabini.avif?alt=media&token=b7dcceea-7e17-4941-9827-452210c29d2b"
    ],
    description:"Kabini is one of the few places where the top three predators of the subcontinent-the tiger, leopard, and wild dogs-roam freely. The forest is teeming with wildlife, including spotted deer, sambar deer, the great Indian squirrel, Indian gaur (bison), and the elusive musk deer. Kabini is also known for its rich birdlife, making it a haven for wildlife enthusiasts.",
    link:"/kabini",
    buttonText:"Wildlife Safari in Kabini"
  },

];

const images = [
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2F1-1.avif?alt=media&token=0d459474-e9ba-4064-891f-38b1ecbc2e7f",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2F2.webp?alt=media&token=540af043-c30e-4c4b-ba16-5f6f72437d66",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2F3.avif?alt=media&token=a0d64d40-8153-40b7-9afe-a085679dd69e",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2F4.webp?alt=media&token=5aee9f17-90b5-415b-bef0-3f80331f4260",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2F5.avif?alt=media&token=00ec0b1f-f367-4867-bd0d-509028623c8e",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2F6.webp?alt=media&token=d815f4d5-ec7f-4a1b-8ef3-e95768aa2850",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2F7.avif?alt=media&token=fce64880-cdea-43d1-8efe-bf234140427f",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2F8-1-1.webp?alt=media&token=aec3f00c-e836-494e-8dc7-6f2e2285e28c",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2F9-1.avif?alt=media&token=7e991f82-ce4c-4bbf-bbfe-9beb5a80cf43",
]

const preloadImages = (imageUrls) => {
  imageUrls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};

function Home() {
  useEffect(() => {
    preloadImages(images);
  }, []);

  return (
      <div>
        <HelmetProvider>
        <Helmet>
          <title>Travel Unbounded: Unforgettable Global Travel Packages | Adventure Awaits</title>
          <meta
            name="description"
            content="Experience personalized travel packages with Travel Unbounded, the best travel agency in Bangalore. Embark on exciting journeys across the globe, from exhilarating African safaris to serene tropical escapes. Book your dream adventure today and enjoy exclusive offers on unforgettable experiences!"
          />
         </Helmet>
      <Navbar />
      <HomeSlider images={images} showOverlayText={true}/>
      <HomeCardSpecialities/>
      <h1 className="home-head text-center mt-5 mb-3">DESTINATIONS</h1>
      <div className="container-fluid mb-5">
          {destinations.map((destination, index) => (
            <HomeCard
              key={destination.name}
              destination={destination}
              index={index}
            />
          ))}
        </div>
        <h1 className="trending-head text-center mt-5 mb-3">TRENDING LEISURE TOURS</h1>
        <TrendingLeisure/>
        <div className="home-view-package-btn-container">
          <Link to="/leisure-packages" className="dest-link">
            <button type="button" id="readMoreBtn" className="home-view-package-btn">
              View Leisure Packages
            </button>
          </Link>
        </div>
        <h1 className="trending-head text-center mt-5 mb-3">TRENDING WILDLIFE TOURS</h1>
        <TrendingWildlife/>
        <div className="home-view-package-btn-container">
          <Link to="/wildlife-packages" className="dest-link">
            <button type="button" id="readMoreBtn" className="home-view-package-btn">
              View Wildlife Packages
            </button>
          </Link>
        </div>
        <Testimonials/>
        <Footer />
      </HelmetProvider>
    </div>
  );
}
export default Home;
