import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import './card.css'

const PackageCard = () => {
  const [packages, setPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPackages = async () => {
      const db = firebase.firestore();
      try {
        const snapshot = await db.collection("PackageTemplate").get();
        const packageList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const keywords = [
            "Majestic Shimla and Manali Adventure",
            "Vietnam",
            "Colourful kerala"
          ];

          const filteredPackages = packageList.filter(pkg => 
            keywords.some(keyword => 
              pkg.id
                .replace(/-/g, ' ')
                .trim()
                .toLowerCase() === keyword.trim().toLowerCase()
            )
          );

        setPackages(filteredPackages);
      } catch (error) {
        console.error("Error fetching packages:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPackages();
  }, []);

  if (isLoading) {
    return (
      <div className='loading-container'>
        <p className='loading'></p>
      </div>
    );
  }

  return (
    <div className="destinations-container">
      <div className="best-package-list">
        {packages.map((pkg) => (
          <div key={pkg.id} className="package-list-card mb-3">
            <img
              src={pkg.bannerImageUrl}
              alt={pkg.destinationName}
              className="list-card-img mb-3"
            />
            <div>
              <h4 className="title-list mb-3">{pkg.destinationHead}</h4>
              <h5 className="title-dates-list bold mb-3">{pkg.noOfDays}</h5>
              <h5 className="title-rate-list bold mb-3">{pkg.cost}</h5>
              <div className="btn-container-list-card">
                <Link
                  to={`/package/${pkg.destinationName.replace(/\s+/g, "-")}`}
                  className="blogs-link"
                >
                  <button className="view-button-list-card">
                    View Itinerary
                  </button>
                </Link>
                <Link to="/form" className="blogs-link">
                  <button className="view-button-list-card-book">
                    Book Now
                  </button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PackageCard;
