import React, { useState } from "react";
import "./form.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import OtpInput from "otp-input-react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { toast, Toaster } from "react-hot-toast";
import { FiX } from "react-icons/fi";
import Select from "react-select";
import { HelmetProvider, Helmet } from 'react-helmet-async';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const getRelatedOptionsData = (selectedValue) => {
  const data = {
    BAN: ["Mysore", "Coorg", "Ooty", "No"],
    BNDVG: ["Marble rocks Jabalpur", "Kanha", "Khajuraho", "No"],
    CRT: ["Nainital", "Sattal", "Pangod", "No"],
    KAB: ["Mysore", "Coorg", "No"],
    PEN: ["Tadoba", "Ramtek temple", "Nagzira wildlife sanctuary", "NO"],
    RTR: ["Jaipur", "Jalana", "Udaipur", "Jailsalmer", "No"],
  };
  return data[selectedValue] || [];
};

const BookingForm = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");

  const [confirmationResult, setConfirmationResult] = useState(null);
  const [relatedOptions, setRelatedOptions] = useState([]);
  const [nearestPlaces, setNearestPlaces] = useState([]);

  const handleSendOTP = async (e) => {
    e.preventDefault();
    try {
      const appVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container"
      );
      const confirmation = await firebase
        .auth()
        .signInWithPhoneNumber(`+${phoneNumber}`, 
        appVerifier);
      setConfirmationResult(confirmation);
      toast.success("OTP sent successfully");
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    try {
      await confirmationResult.confirm(otp);
      toast.success("OTP Verified Successfully");
      setOtp("");
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("Please Enter Correct OTP");
    }
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    country: "",
    destination: "",
    addNearestPlace: [],
    date: "",
    adults: "",
    childrens: "",
    duration: "",
    callbackTime: "",
    queries: "",
    d2h: "",
  });

  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 30);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "destination") {
      const selectedValue = value;
      const relatedOptionsData = getRelatedOptionsData(selectedValue);
      setRelatedOptions(relatedOptionsData);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: selectedValue,
        addNearestPlace: [],
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  const handleNearestPlacesChange = (selectedOptions) => {
    const places = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setNearestPlaces(places);
    setFormData((prevFormData) => ({
      ...prevFormData,
      addNearestPlace: places,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const {
        name,
        email,
        country,
        destination,
        addNearestPlace,
        date,
        adults,
        childrens,
        duration,
        callbackTime,
        queries,
        d2h,
      } = formData;
      const db = firebase.firestore();
      db.collection("formData").add({
        name: name,
        email: email,
        country: country,
        destination: destination,
        addNearestPlace: addNearestPlace,
        date: date,
        adults: adults,
        childrens: childrens,
        duration: duration,
        callbackTime: callbackTime,
        queries: queries,
        d2h: d2h,
      });
      const response = await fetch("https://formbackend.netlify.app/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          country,
          destination,
          addNearestPlace,
          date,
          adults,
          childrens,
          duration,
          callbackTime,
          queries,
          phoneNumber,
          d2h,
        }),
      });
      const data = await response.json();
      console.log(data.message);
      toast.success("Booking Successfull");
      setFormData({
        name: "",
        email: "",
        country: "",
        destination: "",
        addNearestPlace: [],
        date: "",
        adults: "",
        childrens: "",
        duration: "",
        callbackTime: "",
        queries: "",
        d2h:"",
      });
      setPhoneNumber("");
      setNearestPlaces([]);
      if (window.gtag) {
        window.gtag('event', 'conversion', {
          'send_to': 'AW-870473623/KRgLCN7oh9QZEJe_iZ8D',
        });
      } else {
        console.error("Google Tag not initialized");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <>
    <HelmetProvider>
      <Helmet>
        <title>Book your journey now with Travel Unbounded.</title>
        <meta
          name="description"
          content="Book now for an unforgettable journey into the heart of the wild. Don't miss out on thrilling safaris and breathtaking encounters"
        />
        <script type="text/javascript">
          {`
            function gtag_report_conversion(url) {
              var callback = function () {
                if (typeof(url) != 'undefined') {
                  window.location = url;
                }
              };
              gtag('event', 'conversion', {
                  'send_to': 'AW-870473623/JHjsCNys39kZEJe_iZ8D',
                  'event_callback': callback
              });
              return false;
            }
          `}
        </script>
       
      </Helmet>
      <Toaster toastOptions={{ duration: 5000 }} />
      <div className="container-fluid app">
        <div
          onClick={handleGoBack}
          style={{ position: "absolute", top: "10px", right: "10px", cursor:'pointer' }}
        >
          <FiX size={40} />
        </div>
        <div className="book-img">
          <img src="/pug.png" alt="pug-mark" className="pug-mark" />
        </div>
        <h2 className="text-center input-text">Book Your Tour</h2>
        <p className="text-center input-text mb-4">
          Game drives/Safari-zones subject to availability
          <br /> <span>Recommend to plan a travel post 30 days</span>
        </p>
        <form onSubmit={handleSubmit}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-4 col-xl-4">
                <div className="form-group mb-3">
                  <input
                    type="text"
                    className="form-control input-text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Name*"
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <input
                    type="email"
                    className="form-control input-text"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email*"
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <input
                    type="text"
                    className="form-control input-text"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    placeholder="Country Name*"
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <select
                    id="destination"
                    name="destination"
                    value={formData.destination}
                    onChange={handleChange}
                    className="form-control input-text"
                    required
                  >
                    <option value="" disabled>
                      Select Destination*
                    </option>
                    <option value="BAN">Bandipur</option>
                    <option value="BNDVG">Bandhavgarh</option>
                    <option value="CRT">Corbett</option>
                    <option value="KAB">Kabini</option>
                    <option value="KER">Kerala</option>
                    <option value="HIM">Himachal Pradesh</option>
                    <option value="SPR">Satpura</option>
                    <option value="HIM">Bali</option>
                    <option value="KEN">Kenya</option>
                    <option value="PEN">Pench</option>
                    <option value="RTR">Ranthambore</option>
                    <option value="TAN">Tanzania</option>
                    <option value="VTNM">Vietnam</option>
                    <option value="CMBD">Combodia</option>
                    <option value="ICLND">Iceland</option>
                    <option value="SA">South Africa</option>
                  </select>
                </div>
                <div className="form-group mb-3">
                  <Select
                    isMulti
                    options={relatedOptions.map((option, index) => ({
                      value: option,
                      label: option,
                    }))}
                    value={nearestPlaces.map((place) => ({
                      value: place,
                      label: place,
                    }))}
                    onChange={(selectedOptions) =>
                      handleNearestPlacesChange(selectedOptions)
                    }
                    placeholder="Do you want to visit nearest places?"
                  />
                </div>
                <div className="form-group mt-3 mb-3">
                  <input
                    type="number"
                    className="form-control input-text"
                    name="adults"
                    value={formData.adults}
                    onChange={handleChange}
                    placeholder="Number of adults*"
                    required
                  />
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-4 col-xl-4">
                <div className="form-group mb-3">
                  <input
                    type="number"
                    className="form-control input-text"
                    name="childrens"
                    value={formData.childrens}
                    onChange={handleChange}
                    placeholder="Number of children (below 9years)*"
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <input
                    type="text"
                    className="form-control input-text"
                    name="duration"
                    value={formData.duration}
                    onChange={handleChange}
                    placeholder="Duration of stay/No.of nights*"
                    required
                  />
                </div>
                <div className="form-group input-text mb-3">
                  <input
                    type="date"
                    name="date"
                    id="date"
                    value={formData.date}
                    min={minDate.toISOString().split("T")[0]}
                    onChange={handleChange}
                    placeholder="select Date*"
                    required
                  />
                </div>

                <div className="form-group mb-3">
                  <input
                    type="text"
                    className="form-control input-text"
                    name="callbackTime"
                    value={formData.callbackTime}
                    onChange={handleChange}
                    placeholder="Flexible time to reach out to you*"
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <textarea
                    className="form-control input-text"
                    name="queries"
                    value={formData.queries}
                    onChange={handleChange}
                    placeholder="Message"
                    rows={3}
                  />
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-4 col-xl-4">
                <div className="form-group input-text mb-3">
                  <PhoneInput
                    defaultCountry="IN"
                    value={phoneNumber}
                    placeholder="Enter Contact Number*"
                    onChange={(value) => setPhoneNumber(value)}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <button
                    type="button"
                    onClick={handleSendOTP}
                    className="btn btn-primary input-text mb-3"
                  >
                    Send OTP
                  </button>
                </div>
                <div className="form-group mb-3">
                  <div id="recaptcha-container"></div>
                </div>

                <div className="form-group mb-3 mt-3 d-flex flex-column justify-content-center">
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    OTPLength={6}
                    otpType="number"
                    disabled={false}
                    required
                  ></OtpInput>
                  <button
                    type="button"
                    onClick={handleVerifyOTP}
                    className="btn btn-primary input-text mt-3"
                  >
                    Verify OTP
                  </button>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control input-text"
                    name="d2h"
                    value={formData.d2h}
                    onChange={handleChange}
                    placeholder="Enter Unique CODE of DISH D2H"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-center mb-5">
              <button type="submit" className="btn btn-primary input-text">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
      </HelmetProvider>
    </>
  );
};

export default BookingForm;
