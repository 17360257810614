import React, {useState, useEffect} from 'react';
import Navbar from '../Navbar';
import Footer from '../Contact';
import HomeSlider from '../HomeSlider';
import {Link} from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import './index.css';

const images = [
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Home%2Fhimachal-3647248_1920.jpg?alt=media&token=55a05050-e090-402c-8aa4-d8912a081fcf",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/packageTemplate%2FManali%20copy.jpg?alt=media&token=a0af0bcd-2a99-4666-bfbb-2407a29ab20b",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/packageTemplate%2FSpiti%209N%2010D.jpg?alt=media&token=464c0bf6-40d4-47fc-9feb-0d4c7a6fb961"
]

const DestinationKerala = () => {
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        const fetchPackages = async () => {
          const db = firebase.firestore();
          try {
            const snapshot = await db.collection("PackageTemplate").get();
            const packageList = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPackages(packageList);
          } catch (error) {
            console.error("Error fetching packages:", error);
          } 
        };
    
        fetchPackages();
      }, []);

      const himachalPackages = packages.filter(pkg => 
        pkg.destinationName.toLowerCase().includes('spiti') || 
        pkg.destinationName.toLowerCase().includes('lahaul') ||
        pkg.destinationName.toLowerCase().includes('shimla')
      );

    return (
        <div>
            <Navbar/>
            <HomeSlider images={images}/>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12 col-xl-12'>
                        <h1 className='home-head mt-5'>Himachal Pradesh</h1>
                        <p className='destination-description'>Located in northern India, is a popular tourist destination known for its stunning
                        landscapes, pleasant climate, and adventure opportunities. Himachal Pradesh is a
                        treasure trove of natural beauty, offering something for every type of traveler, from tranquil
                        retreats to thrilling adventures.</p>
                        <p className='destination-sub-head mt-5'>Geography</p>
                        <p className='destination-description'>Himachal Pradesh, situated in the northern part of India, is a state renowned for its diverse
                        and dramatic geography. The state is predominantly mountainous, lying within the western
                        Himalayas. It features several mountain ranges and peaks, including the Pir Panjal Range,
                        the Dhauladhar Range, and parts of the Greater Himalayas.</p>
                        <p className='destination-sub-head mt-5'>International airports to visit Himachal Pradesh </p>
                        <p className='destination-description'><b>Indira Gandhi International Airport (Delhi)</b></p>
                        <p className='destination-description'> Indira Gandhi International Airport is the primary international gateway to northern India and serves as a major hub for travelers heading to Himachal Pradesh. Located in the capital city of Delhi, this airport offers a wide range of domestic and international flights. From Delhi, you can easily travel to Himachal Pradesh by road or by taking a connecting flight to one of the regional airports in Himachal. The well-connected road network and frequent flights make Delhi a convenient choice for visitors.</p>                        
                        <p className='destination-description'><b>Chandigarh International Airport (Chandigarh)</b></p>
                        <p className='destination-description'>Chandigarh International Airport serves the city of Chandigarh and its surrounding regions, including Himachal Pradesh. This airport offers both domestic and international flights, making it a convenient option for travelers heading to Himachal Pradesh. The airport's proximity to the northern states of India reduces travel time to popular Himachal destinations like Shimla, Manali, and Dharamshala. With efficient road links, you can quickly reach your destination in Himachal Pradesh from Chandigarh.</p>
                        <p className='destination-description'><b>Sri Guru Ram Dass Jee International Airport (Amritsar)</b></p>
                        <p className='destination-description'>Sri Guru Ram Dass Jee International Airport is located in Amritsar, a major city in Punjab, close to the border with Himachal Pradesh. This airport provides international and domestic flight options, serving as another gateway to Himachal Pradesh. Amritsar’s airport is especially useful for travelers coming from or passing through the Punjab region. From Amritsar, you can drive to Himachal Pradesh or catch a domestic flight to other airports within Himachal.</p>
                        <p className='destination-sub-head mt-5'>Climate</p>
                        <p className='destination-description'><b>Varied Climate:</b> The state experiences a range of climates due to its varied altitudes, from
                        subtropical in the lower regions to alpine and cold desert climates in the high-altitude
                        areas.</p>
                        <p className='destination-description'><b>Monsoon:</b> Heavy rainfall in the lower and mid-hills during the monsoon season (June to
                            September), with minimal rainfall in high-altitude regions.</p>
                        <p className='destination-sub-head mt-5'>Culture</p>
                        <p className='destination-description'><b>Languages:</b>Hindi is the official language, but various regional languages and dialects are
                        spoken, including Punjabi, Pahari, and Tibetan.</p>
                        <p className='destination-description'><b>Festivals:</b>Celebrates various festivals like Dussehra, Diwali, Holi, and regional festivals
                        like the Kullu Dussehra and Lavi Fair.</p>
                        <p className='destination-description'><b>Arts and Crafts:</b>Known for traditional handicrafts, including hand-woven carpets,
                        woolens, and wood carvings.</p>
                        <p className='destination-sub-head mt-5'>VISA formalities for Foreign Nationals</p>
                        <p className='destination-description'>Please <a href='https://indianvisaonline.gov.in/evisa/tvoa.html' target='__blank'>Click</a> and check the procedure for
                        your respective Country.</p>
                        <p className='destination-sub-head mt-5'>Tourism</p>
                        <p className='destination-description'>Here are some key tourist locations and attractions in Himachal Pradesh:</p>
                        <p className='destination-sub-head'>1. Shimla</p>
                        <p className='destination-description'><b>Mall Road:</b> A bustling street with shops, cafes, and colonial architecture.</p>
                        <p className='destination-description'><b>Jakhoo Temple:</b> Dedicated to Lord Hanuman, located on Jakhoo Hill with panoramic
                        views of Shimla.</p>
                        <p className='destination-description'><b>Christ Church:</b> A historic church known for its neo-Gothic architecture.</p>
                        <p className='destination-description'><b> Kufri:</b> A popular spot for adventure activities like skiing and tobogganing, especially in
                        winter.</p>
                        <p className='destination-sub-head'>2. Manali</p>
                        <p className='destination-description'><b>Solang Valley:</b> Famous for its adventure sports such as paragliding, skiing, and zorbing.</p>
                        <p className='destination-description'><b>Rohtang Pass:</b> A high mountain pass offering breathtaking views and snow activities.
                        Note that it's open seasonally.</p>
                        <p className='destination-description'><b>Old Manali:</b>Known for its vibrant cafes, boutiques, and a relaxed atmosphere.</p>
                        <p className='destination-description'><b>Hadimba Temple:</b> An ancient temple set amidst cedar forests, dedicated to Hadimba
                        Devi.</p>
                        <p className='destination-sub-head'>3. Dharamshala and McLeod Ganj</p>
                        <p className='destination-description'><b>Dalai Lama Temple Complex:</b> The residence of the Dalai Lama and a center for Tibetan
                        Buddhism.</p>
                        <p className='destination-description'><b>Bhagsu Waterfall:</b> A picturesque waterfall located near McLeod Ganj.</p>
                        <p className='destination-description'><b>Dharamkot:</b> A serene village offering panoramic views and hiking opportunities.</p>
                        <p className='destination-sub-head'>4. Shimla District</p>
                        <p className='destination-description'><b>Chail:</b>Known for its scenic beauty and the highest cricket ground in the world.</p>
                        <p className='destination-description'><b>Tattapani:</b> Famous for its hot sulfur springs and river rafting opportunities.</p>
                        <p className='destination-sub-head'>5. Kullu Valley</p>
                        <p className='destination-description'><b>Great Himalayan National Park:</b> A UNESCO World Heritage Site with diverse flora and
                        fauna.</p>
                        <p className='destination-description'><b>Kasol:</b> Known for its relaxed atmosphere, cafes, and trekking routes.</p>
                        <p className='destination-sub-head'>6. Spiti Valley</p>
                        <p className='destination-description'><b>Kaza:</b> The administrative center of Spiti, surrounded by monasteries and unique
                        landscapes.</p>
                        <p className='destination-description'><b>Key Monastery:</b> An important Buddhist monastery perched on a hill with stunning views.</p>
                        <p className='destination-description'><b>Chandratal Lake:</b> A high-altitude lake offering breathtaking views and trekking
                        opportunities.</p>
                        <p className='destination-sub-head'>7. Lahaul Valley</p>
                        <p className='destination-description'><b>Sissu:</b> A picturesque village with waterfalls and serene landscapes.</p>
                        <p className='destination-description'><b>Tandi:</b> Known as the confluence point of the Chandra and Bhaga rivers.</p>
                        <p className='destination-sub-head'>8. Palampur</p>
                        <p className='destination-description'><b>Tea Gardens:</b> Explore the tea gardens and learn about tea processing.</p>
                        <p className='destination-description'><b>Baijnath Temple:</b> An ancient Shiva temple known for its historical significance.</p>
                        <p className='destination-sub-head'>9. Dalhousie</p>
                        <p className='destination-description'><b>Khajjiar:</b> Often referred to as the &quot;Mini Switzerland of India,&quot; known for its lush meadows
                        and dense forests.</p>
                        <p className='destination-description'><b>Dainkund Peak:</b> Offers panoramic views of the surrounding valleys and peaks.</p>
                        <p className='destination-sub-head'>10. Nahan</p>
                        <p className='destination-description'><b>Rani Tal:</b> A serene lake surrounded by beautiful gardens.</p>
                        <p className='destination-description'><b>Renuka Lake:</b> A large lake with a picturesque setting and boating facilities.</p>
                        <p className='destination-sub-head mt-5'>Adventure Activities:</p>
                        <p className='destination-description'><b>Trekking:</b> Popular trails include the Hampta Pass, Pin Parvati Pass, and the Triund Trek.</p>
                        <p className='destination-description'><b>Camping:</b> Various locations such as Manali, Kasol, and Dharamshala offer camping
                        opportunities.</p>
                        <p className='destination-description'><b>River Rafting:</b> Available in places like Kullu and Tattapani.</p>
                        </div>
                    </div>
                </div>
                <p className='destination-package-head mb-3 mt-5'>View Our Himachal Pradesh Packages</p>
                    <div className="destinations-container mb-5">
                    <div className="best-package-list">
                    {himachalPackages.map((pkg) => (
                        <div key={pkg.id} className="package-list-card mb-3">
                        <img
                            src={pkg.bannerImageUrl}
                            alt={pkg.destinationName}
                            className="list-card-img mb-3"
                        />
                        <div>
                            <h4 className="title-list mb-3">{pkg.destinationHead}</h4>
                            <h5 className="title-dates-list bold mb-3">{pkg.noOfDays}</h5>
                            <h5 className="title-rate-list bold mb-3">{pkg.cost}</h5>
                            <div className="btn-container-list-card">
                            <Link
                                to={`/package/${pkg.destinationName.replace(/\s+/g, "-")}`}
                                className="blogs-link"
                            >
                                <button className="view-button-list-card">
                                View Itinerary
                                </button>
                            </Link>
                            <Link to="/form" className="blogs-link">
                                <button className="view-button-list-card-book">
                                Book Now
                                </button>
                            </Link>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
            </div>
            <Footer/>
        </div>
    );
}

export default DestinationKerala;
