import React, { useState,useEffect,useRef } from 'react';
import './index.css';
import Navbar from '../Nav';
import {Link} from 'react-router-dom'
import SideBar from '../SidebarMenu2';
import { IoIosArrowRoundDown } from "react-icons/io";
import { BsBrightnessHighFill } from "react-icons/bs";
import { IoHomeOutline } from "react-icons/io5";
import AfricaGallery from '../AfricaGallery'
import { HelmetProvider, Helmet } from 'react-helmet-async';

const images1 = [
  '/africa-2/gallery-1/a-2-g-1-1.jpg',
  '/africa-2/gallery-1/a-2-g-2-3.jpg',
  '/africa-2/gallery-1/a-2-g-1-4.jpg',
  '/africa-2/gallery-1/a-2-g-1-5.jpg',
  '/africa-2/gallery-1/a-2-g-1-6.jpg',
  '/africa-2/gallery-1/a-2-g-1-7.jpg',
  '/africa-2/gallery-1/a-2-g-1-8.jpg'
]

const images2 = [
  '/africa-2/gallery-2/a-2-g-2-1.jpg',
  '/africa-2/gallery-2/a-2-g-2-2.jpg',
  '/africa-2/gallery-2/a-2-g-2-3.jpg',
  '/africa-2/gallery-2/a-2-g-2-4.jpg',
  '/africa-2/gallery-2/a-2-g-2-5.jpg',
  '/africa-2/gallery-2/a-2-g-2-6.jpg',
  '/africa-2/gallery-2/a-2-g-2-7.jpg',
  '/africa-2/gallery-2/a-2-g-2-8.jpg'
]

const images3 = [
  '/africa-2/gallery-3/a-2-g-3-1.jpg',
  '/africa-2/gallery-3/a-2-g-3-2.jpg',
  '/africa-2/gallery-3/a-2-g-3-3.jpg',
  '/africa-2/gallery-3/a-2-g-3-4.jpg',
  '/africa-2/gallery-3/a-2-g-3-5.jpg',
  '/africa-2/gallery-3/a-2-g-3-6.jpg',
  '/africa-2/gallery-3/a-2-g-3-7.jpg',
]


const images4 = [
  '/africa-2/gallery-4/a-2-g-4-1.jpg',
  '/africa-2/gallery-4/a-2-g-4-2.jpg',
  '/africa-2/gallery-4/a-2-g-4-3.jpg',
  '/africa-2/gallery-4/a-2-g-4-4.jpg',
  '/africa-2/gallery-4/a-2-g-4-5.jpg',
  '/africa-2/gallery-4/a-2-g-4-6.jpg',
  '/africa-2/gallery-4/a-2-g-4-7.jpg',
]

const images5 = [
  '/africa-2/gallery-5/a-2-g-5-1.jpg',
  '/africa-2/gallery-5/a-2-g-5-2.jpg',
  '/africa-2/gallery-5/a-2-g-5-3.jpg',
  '/africa-2/gallery-5/a-2-g-5-4.jpg',
]

const images6 = [
  '/africa-2/gallery-6/a-2-g-6-1.jpg',
  '/africa-2/gallery-6/a-2-g-6-2.jpg',
  '/africa-2/gallery-6/a-2-g-6-3.jpg',
  '/africa-2/gallery-6/a-2-g-6-4.jpg',
  '/africa-2/gallery-6/a-2-g-6-5.jpg',
]

const preloadImages = (imageUrls) => {
  imageUrls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};

const Africa2 = () => {

  const sec0 = useRef(null);
  const sec1 = useRef(null);
  const sec2 = useRef(null);
  const sec3 = useRef(null);
  const sec4 = useRef(null);
  const sec5 = useRef(null);
  const sec6 = useRef(null);
  const sec7 = useRef(null);

  const scrollHandler = (elemRef) => {
    window.scrollTo({ top: elemRef.current.offsetTop, behavior: 'smooth', block: 'start' });
    toggleMenu();
  };

  const scroll = (elemRef) => {
    window.scrollTo({ top: elemRef.current.offsetTop, behavior: 'smooth', block: 'start' });
  };
  

  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const [activeSection, setActiveSection] = useState(null);

  const handleScroll = () => {
    const sections = document.querySelectorAll('.section-container');

    sections.forEach((section) => {
      const rect = section.getBoundingClientRect();
      if (rect.top <= 100 && rect.bottom >= 300) {
        setActiveSection(section.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const allImages = [...images1, ...images2, ...images3, ...images4, ...images5, ...images6];
    preloadImages(allImages);
  }, []);

  return (
    <>
    <HelmetProvider>
     <Helmet>
        <title>South Africa Luxury Tour Packages: Explore Safaris and Scenic Wonders</title>
        <meta
          name="description"
          content="Discover the beauty of South Africa with our luxury tour packages. Experience
          breathtaking safaris, stunning landscapes, and rich culture. Book your unforgettable South African
          adventure today!"
        />
    </Helmet>
    <div className='tour-app'>
      <div className='section-container' ref={sec0}>
        <div className='fixed-image' style={{ backgroundImage: 'url(/africa-2/africa-2-1.jpg)'}}>
        
        </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex'>
            <h2 className='head-2'>“LIFE IS NOT MEASURED BY THE NUMBER OF BREATHS WE TAKE,
            BUT BY THE MOMENTS THAT TAKE OUR BREATH AWAY.”</h2>
            <p>- Maya Angelou</p>
            <IoIosArrowRoundDown size={90} style={{ cursor: 'pointer',marginTop:'50px'}} onClick={() => scroll(sec1)}/>
          </div>
        </div>
      </div>

      <div className='section-container' id='overview' ref={sec1}>
        <div className='fixed-image' style={{ backgroundImage: 'url(/africa-2/africa-2-2.jpg)' }}>
        </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex-2'>
            <div className={`fixed-header-2 ${activeSection === 'overview' ? 'fixed' : ''}`} >
            <h4 className='section'>Overview</h4>
            <hr style={{width:'100%'}}/>
            <h6>Itinerary at a glance</h6>
            </div>
        <div className='scroll'>
          <p style={{fontWeight:'bold'}}>Day 1</p>
          <p>Upon arrival at Eastgate airport, you will be met and greeted by our driver. He will then transfer you to your first lodge in the Klaserie Game Reserve.</p>
          <p style={{fontWeight:'bold'}}>Day 1 - 4</p>
          <p>You will spend 3 nights in the beautiful Klaserie Drift Misava Camp.
          On your first day, you will have a sunset game drive before returning to the lodge on time to freshen up and enjoy a delicious dinner.</p>
          <p>Every day, you will be going on sunrise and sunset game drives, looking for wild animals with your knowledgeable guide and tracker who will answer all your questions and tell you fascinating information.</p>
          <p>On your last day and after your last game drive in Klaserie, you will enjoy breakfast at the lodge before being picked up by your driver.
          Klaserie Drift Misava Safari Camp</p>
          <p>3 nights</p>
          <p style={{fontWeight:'bold'}}>Day 4</p>
          <p>You will be transferred to your second lodge in the Thornybush Game reserve.</p>
          <p style={{fontWeight:'bold'}}>Day 4 - 8</p>
          <p>The game drives program is similar to the previous lodge, but this is a different reserve, which your guide and tracker will make you discover with all the passion and expertise to ensure the best possible sightings. Every day is different and nature is unpredictable, this is part of the thrill of being on safari!</p>
          <p>On your last morning, your driver will pick you up from your lodge and transfer you to Eastgate airport on time for your flight. The time will depend on your flight time but you will still be able to enjoy a sunrise game drive.</p>
          <p>Serondella Lodge</p>
          <p>4 nights</p>
          <p style={{fontWeight:'bold'}}>Day 8</p>
          <p>It is sadly time to say goodbye to South Africa, unless you plan to visit Cape Town, which is only a short flight away, for a change of scenery with many unique landmarks, excursions and renown cuisine.
          Alternatively, you may fly to Victoria Falls in Zimbabwe, one of the seven natural wonders of the world, which is another bucket list destination.</p>
          <p>We hope you will leave Africa with memories of a lifetime and perhaps even the desire to return. Once you caught the safari bug, it never leaves you!</p> 
            </div>
          </div>
        </div>
      </div>

      <div className='section-container' id='map' ref={sec2}>
        <div className='fixed-image' style={{ backgroundImage: 'url(/africa-2/africa-2-3-3.jpg)' }}> 
        </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex-2'>
          <div className={`fixed-header-2 ${activeSection === 'map' ? 'fixed' : ''}`} >
          <h4 className='section'>MAP</h4>
            <hr style={{width:'100%'}}/>
            <h6>Visualize Your Journey</h6>
            </div>
            <div className='scroll'>
             <img src='/africa-2/africa-2-map.jpeg' alt='' className='map-2'/>
             <p style={{fontWeight:'bold',marginTop:'10px'}}>Key Dates</p>
             <p>1) Day 1 - 4: Klaserie Drift Misava Safari Camp, Kruger Park</p>
             <p>2) Day 4 - 8: Serondella Lodge, Thornybush Game Reserve</p>
            </div>
          </div>
        </div>
      </div>

      <div className='section-container' id='timbavati' ref={sec3}>
        <div className='fixed-image' style={{ backgroundImage: 'url(/africa-2/africa-2-4-4.jpg)' }}> 
        </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex-3'>
          <div className={`fixed-header ${activeSection === 'timbavati' ? 'fixed' : ''}`} >
            <h4 className='section'>Klaserie Private Game Reserve</h4>
            <hr style={{width:'100%'}}/>
            <h6>Aria Overview</h6>
            </div>
            <div className='scroll-2'>
             <p>Part of the Greater Kruger Park area, Klaserie Private Game Reserve is one of the largest privately owned reserves in South Africa, covering an impressive 148,000 acres of pristine wildlife. It is home to the Big Five - lion, leopard, elephant, buffalo and rhino - as well as some of Africa’s less spotted and endangered species like wild dog and cheetah. The landscape is varied and includes typical Acacia savanna, riverine bushveld, as well as vast stretches of mopane woodland.</p>
             <img src='/africa-2/africa-sec-1-1.jpg' alt='' className='map'/>
             <div className='image-flex'>
             <img src='/africa-2/africa-sec-1-2.jpg' alt='' className='map-1'/>
             <img src='/africa-2/a-4.jpeg' alt='' className='map-1'/>
             </div>
             <p style={{paddingTop:'30px'}}>Klaserie is fiercely focused on nature conservation and low-impact tourism. Limited visitor numbers allow for a more exclusive experience with more time at sightings and fewer other vehicles around. Guests can enjoy plains game on the open veld while the Klaserie River is popular with hippo pods, playful elephants, buffalo, and crashes of rhino.</p>
            <p>The Kruger National Park, the Panorama Route, Blyde River Canyon, and various endangered species centers are all a day-trip away. Bush walks and learning to track big game on foot may also be possible.</p>
           </div>
          </div>
        </div>
      </div>

      <div className='section-container' id='thabamati' ref={sec4}>
        <div className='fixed-image' style={{ backgroundImage: 'url(/africa-2/africa-2-5.jpg)' }}> 
        </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex-3'>
          <div className={`fixed-header ${activeSection === 'thabamati' ? 'fixed' : ''}`} >
            <h4 className='section'>Klaserie Drift Misava Safari Camp</h4>
            <hr style={{width:'100%'}}/>
            </div>
            <div className='scroll-2'>
              <h6>Property Overview</h6>
            <p>Klaserie Drift Misava Safari Camp is set on prime land in the heart of the Greater Kruger National Park, with 11,000 hectares of traverse, offering a true, exclusive retreat into nature. Located in Klaserie Private Nature Reserve, which is known for its superb game viewing, abundant fauna and flora, and diverse topography, there are daily flights from Johannesburg and Cape Town or it is only a 6 hour self drive from Johannesburg. Klaserie Drift Misava Safari Camp features the big five, incredibly diverse landscapes and beautiful, luxurious five-star accommodation with all the modern amenities.</p>
            <AfricaGallery images={images1}/>
            <p>A contemporary wooden veranda complete with infinity pool, day beds, and dining area overlooking the unimpeded views of the African bushveld, form the main attraction at Misava Safari Camp. Indulge in superior luxury as elephants, monkeys and buffalo take a drink in the Klaserie river below. Quench your thirst at the chic open-air bar for an inclusive selection of South African wines and cocktails or share stories around an open fire under the starlit night sky.</p>
            <p>Spacious yet warmly decorated, the main lodge comprises a stylish lounge and library area, ideal for unwinding with a good book or movie on a cool winter’s evening. Whatever the weather, the highly trained chefs, spa therapist, and friendly staff are always on hand to deliver an unforgettable experience.</p>
            <hr style={{width:'100%'}}/>
              <h6>Experience & Activities</h6>
              <p>Whether guests are into animals, birds, relaxation, or just a family escape, a stay at Klaserie Drift has something for everyone. Every morning and evening, guests will delve deep into nature on an exciting game drive or walking safari. </p>
              <AfricaGallery images={images2}/>
              <p>During the day, one can book a treatment with a qualified spa therapist, and families can enjoy a fun-filled kids activity after breakfast. </p>
              <div className='image-flex'>
             <img src='/africa-2/a-sec-2-1.jpg' alt='' className='map-1'/>
             <img src='/africa-2/a-sec-2-2.jpg' alt='' className='map-1'/>
             </div>
              <hr style={{width:'100%'}}/>
              <h6>Accommodations</h6>
              <p style={{fontWeight:'bold',fontStyle:'italic'}}>Misava River View</p>
              <p>With endless views of the iconic Klaserie river, guests will be forgiven for not wanting to leave after a stay in one of two river view suites. Ideal for a romantic celebration or just to add that extra bit of luxury to a stay, these suites offer the height of privacy and exclusivity. Lofty thatched roofs framed by wooden beams, natural wooden furniture and muted earth tones lend a spacious but warm feeling to each individually-styled suite. Guests can expect air-conditioning, ceiling fans, tea and coffee facilities, a  private deck, and luxurious indoor and outdoor showers.</p>
              <AfricaGallery images={images3}/>
              <hr style={{width:'100%'}}/>
              <h6 style={{paddingBottom:'20px'}}>Fast Facts</h6>
              <div className='highlights'>
              <BsBrightnessHighFill size={70} style={{marginRight:'50px'}}/>
              <div>
                <p style={{fontWeight:'bold'}}>Highlights</p>
                <p>Located in the heart of the Greater Kruger National Park</p>
                <p>Features the Big 5, incredibly diverse landscapes and beautiful, luxurious five-star accommodations</p>
                <p>One of the original and oldest camps in the Klaserie Private Nature Reserve</p>
              </div>
              </div>
              <div className='highlights'>
              <IoHomeOutline size={30} style={{marginRight:'60px'}}/>
              <div>
                <p style={{fontWeight:'bold'}}>Quick Facts</p>
                <p>Six tents </p>
                <p>Open plan lounge</p>
                <p>TV room with full DSTV</p>
                <p>Open plan kitchen</p>
                <p>Dining area</p>
                <p>Spa</p>
                <p>Pool</p>
                <p>Boma</p>
                <p>Viewing deck</p>
                <p>Security (electric fence)</p>
                <p>Children policy: 8+</p>
                <p>Wifi</p>
              </div>
              </div>
              </div>
          </div>
        </div>
      </div>

      <div className='section-container' id='game' ref={sec5}>
        <div className='fixed-image' style={{ backgroundImage: 'url(/africa-1/africa-6-1.jpg)' }}> 
        </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex-3'>
          <div className={`fixed-header ${activeSection === 'game' ? 'fixed' : ''}`} >
            <h4 className='section'>Thornybush Game Reserve</h4>
            <hr style={{width:'100%'}}/>
            <h6>Aria Overview</h6>
            </div>
            <div className='scroll-2'>
              <p>Thornybush Game Reserve is part of the Greater Kruger National Park and is famed for its huge variety of wildlife including the Big Five - lion, leopard, elephant, buffalo and rhino. This 34,500 acre private reserve recently dropped fences with its neighbors, allowing animals to move freely across a huge expanse of protected wilderness. Being in the private reserve affords guests an exclusive game viewing experience, with a limited number of lodges and visitors. This allows for intimate, uncrowded encounters with wildlife.</p>
              <div className='image-flex'>
             <img src='/africa-2/a-1-new.jpg' alt='' className='map-1'/>
             <img src='/africa-2/a-3.jpeg' alt='' className='map-1'/>
             </div>
             <div className='image-flex'>
             <img src='/africa-1/africa-sec-2-4.jpg' alt='' className='map-1'/>
             <img src='/africa-2/a-2.jpeg' alt='' className='map-1'/>
             </div>
             <p>The game density is excellent, especially lion and leopard, and guests are able to get as close to the action as possible. Bush walks and night drives offer an exciting way to encounter wildlife.</p>
            </div>
          </div>
        </div>
      </div>

      <div className='section-container' id='siviti' ref={sec6}>
      <div className='fixed-image' style={{ backgroundImage: 'url(/africa-2/africa-2-7.jpg)' }}> 
      </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex-3'>
          <div className={`fixed-header ${activeSection === 'siviti' ? 'fixed' : ''}`} >
            <h4 className='section'>Serondella Lodge</h4>
            <hr style={{width:'100%'}}/>
            </div>
            <div className='scroll-2'>
              <h6>Property Overview</h6>
            <p>The stunningly contemporary Serondella Lodge is the ultimate of intimate game lodges, situated in a riverine forest on the banks of the ephemeral Monwana river in the world-famous Thornybush Nature Reserve, which is part of the Greater Kruger National Park. </p>
            
            <AfricaGallery images={images4} />
            <p >With huge feverberries, appleleaves, and boerbeans as the background, the view over the lodge waterhole is almost always interrupted by the animals that gave Serondella its name – elephants. 
            </p>
            <p>The name Serondella means “the place of the elephants” in the local isiStsonga language. The lodge is sublime in all aspects- décor, food design, and hosting. </p>
            <hr style={{width:'100%'}}/>
              <h6>Experience & Activities</h6>
              <p>The lodge offers exceptional game viewing in custom-designed land cruisers as well as walking safaris. </p>
              <AfricaGallery images={images5} />
              
              <hr style={{width:'100%'}}/>
              <h6>Accommodations</h6>
              <p style={{fontWeight:'bold', fontStyle:'italic'}}>Luxury Suite</p>
              <p>Two private suites are positioned under the trees along the edge of our waterhole. They are designed to sleep two people and offer ample space to relax and unwind in the privacy of the suite. Each room has a comfortable lounge, outside deck, plunge pool, and a privately-positioned bathtub.</p>
              <AfricaGallery images={images6} />
              <hr style={{width:'100%'}}/>
              <h6 style={{paddingBottom:'20px'}}>Fast Facts</h6>
              <div className='highlights'>
              <BsBrightnessHighFill size={70} style={{marginRight:'50px'}}/>
              <div>
                <p style={{fontWeight:'bold'}}>Highlights</p>
                <p>Located in the Thornybush Nature Reserve
                Heated infinity swimming pool with a view of the waterhole</p>
                <p>The lodge is sublime in all aspects- décor, food design, and hosting</p>
                <p>Elephants frequent the area and stop by for a drink</p>
              </div>
              </div>
              <div className='highlights'>
              <IoHomeOutline size={30} style={{marginRight:'60px'}}/>
              <div>
                <p style={{fontWeight:'bold'}}>Quick Facts</p>
                <p>Four suites</p>
                <p>Restaurant </p>
                <p>Lounge</p>
                <p>Bar</p>
                <p>Gym </p>
                <p>Spa</p>
                <p>Swimming pool</p>
                <p>Curio shop</p>
                <p>Waterhole</p>
                <p>Electricity 220v</p>
                <p>Wine cellar</p>
                <p>Library</p>
                <p>Wifi </p>
              </div>
              </div>
              </div>
          </div>
        </div>
      </div>
      <div className='section-container' id='cost' ref={sec7}>
        <div className='fixed-image' style={{ backgroundImage: 'url(/africa-1/africa-8-1.jpg)' }}>
        </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex-3'>
          <div className={`fixed-header ${activeSection === 'cost' ? 'fixed' : ''}`} >
            <h4 className='section'>Costs</h4>
            <hr style={{width:'100%'}}/>
            </div>
            <div className='scroll-2'>
              <p style={{fontWeight:'bold'}}>Honeymooners option 2: Minimum 2 pax required, USD 7588 per pax</p>
             <p style={{fontWeight:'bold'}}>Notes</p>
             <p>- Accommodation is subject to availability at the time of booking.</p>
             <p>- Rates quoted are subject to change without prior notice by third party suppliers.</p>
             <p>- Due to increasing fuel prices, the road transfer rates are subject to change without notice.</p>
             <p style={{fontWeight:'bold'}}>This cost includes:</p>
             <p>All transfers to, from Eastgate airport and between lodges.</p>
             <p>Klaserie and Thornybush reserves conservation fees</p>
             <p>All meals</p>
             <p>All local drinks (soft & alcoholic drinks)</p>
             <p>Two game drives per full day</p>
             
             <p style={{fontWeight:'bold'}}>This cost does not include: </p>
             <p>International flights</p>
             <p>Internal flights to and from Eastgate</p>
             <p>Medical and Travel insurance</p>
             <p>Tips</p>
             <p>Items of personal nature </p>
             <p>Premium brand drinks</p>
             <p>Anything not mentioned above </p>
             <p style={{fontWeight:'bold'}}>Note:</p>
             <p><b>Season:</b> We have a wet season when it is very hot, humid and can see big rains at times from October/November to April. From May until September/October, we have the dry season with morning, evening and night getting colder but the days are very pleasant and warm. July / August is the peak game viewing season as the bush is dry, animals gather around waterholes and are easier to spot. This is also a great season for walking safaris.</p>
             <p><b>Rates:</b> there are set rates throughout the year except at peak seasons, which is usually December and Easter holidays, where no special applies and rates might be higher, it is also a busier time in lodges and the reserves.</p>
             <p style={{fontStyle:'italic', color:'#ccc'}}>Powered by ,<br/> Travel Unbounded</p>
             </div>
             <div className="text-center-africa">
              <Link to="/form">
                <button type="button" className="book-btn">
                  Book Now
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    </HelmetProvider>
    </>
  );
}

export default Africa2;
