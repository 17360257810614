import React, {useState, useEffect} from 'react';
import './index.css';
import { useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BsArrowDownSquareFill } from "react-icons/bs";
import {Link} from 'react-router-dom';
//import Overview from "../Overview";
import Navbar from "../Navbar";
import Footer from "../Contact";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";


const DestinationVietnam = () => {
    const sec0 = useRef();
    const sec1 = useRef();
    const sec2 = useRef();
    const sec3 = useRef();
    const sec4 = useRef();
    const sec5 = useRef();
    const sec6 = useRef();
    const sec7 = useRef();
    const sec8 = useRef();
    const sec9 = useRef();
    
  const scrollHandler = (elemRef) => {
    window.scrollTo({ top: elemRef.current.offsetTop, behavior: "smooth" });
  };
  const [packages, setPackages] = useState([]);

    useEffect(() => {
        const fetchPackages = async () => {
          const db = firebase.firestore();
          try {
            const snapshot = await db.collection("PackageTemplate").get();
            const packageList = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPackages(packageList);
          } catch (error) {
            console.error("Error fetching packages:", error);
          } 
        };
    
        fetchPackages();
      }, []);

      const vietnamPackages = packages.filter(pkg => 
        pkg.destinationName.toLowerCase().includes('vietnam')
      );
    

    return (
        <>
        <HelmetProvider>
      <Helmet>
        <title>Vietnam Travel Packages | Best Vietnam Tours & Vacation Deals | Travel Unbounded</title>
        <meta
          name="description"
          content="Explore the best Vietnam travel packages with Travel Unbounded. Discover top Vietnam tours, amazing vacation deals, and stunning destinations. Book your Vietnam holiday today!"
        />
      </Helmet>
      <div ref={sec0}>
        <Navbar />
      </div>
      <div className="video-banner" ref={sec1}>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Home%2Fbridge.jpg?alt=media&token=8bc0f132-8cbe-4c36-bcac-d69055769831"
          alt="vietnam"
          className="destination-img-vietnam"
        />
        <div className="button-banner">
          <BsArrowDownSquareFill
            size={30}
            onClick={() => scrollHandler(sec2)}
            style={{ color: "white" }}
          />
        </div>
      </div>

      <div className="container" ref={sec2}>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 col-xl-12">
            <div className="it-container data-container mt-3">
              <h2 className="bold mb-3 package-head">
              Vietnam
              </h2>
              <p className="dest-text">
              With its very long history, culture and breathtaking landscapes Vietnam today as one of the most
            popular holiday destinations of the World. Vietnam is one lace which can be visited all around the
            year. The weather across the country is suitable for various seasons. It is also one of the most
            tourists friendly places and very affordable compared to a lot of other countries in the World. It is
            one of the finest holiday destinations for friends and families. It offers different activities for different
            age groups.
              </p>
              <h2 className="bold mb-3 package-head">Must Visit spots - Hanoi</h2>
              <p className="dest-text">
              Halong Bay - Peaceful fishing villages, Huge lime stone mountains growing from the sea, Pristine
            beaches, Mysterious caves and grottos make Haling bay a World heritage site. One can opt for a
            day cruise combined with many fun filled activities like kayaking, snorkelling etc or even take a
            over night cruise on the Halong bay ad enjoy its beauty to it full.
              </p>
              <p className="dest-text">
              Old Quarters - These 36 Streets, is one of Hanoi&#39;s oldest neighbourhoods and most popular
            attractions. During the yesteryears, the King’s dwellers used to own shops and manufacture very
            intricate jewellery for the Kings and the Queens. Now the shops sell various curios, bags,
            garments, jewellery etc to the visitors. This place has to be explored by foot or the rickshaw ( a
            traditional wheeled Vehcile maned by a human ).
              </p>
              <p className="dest-text">
              Nin Binh - This place is known as the Halong bay of the land. The mystical lime stone hills towering
            from the serene river is one of the most peaceful one can visit. History says that the poets used to
            sit in this quiet place and write their masterpieces. The row boats take the guests for a tour at this
            place.
              </p>
              <div className="scroll-container">
                <BsArrowDownSquareFill
                  size={30}
                  onClick={() => scrollHandler(sec3)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container" ref={sec3}>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 col-xl-12">
            <div className="it-container data-container mt-5">
              <h2 className="bold mb-3 package-head">Must Visit spots - Danang</h2>
              <p className="dest-text">
              My Khe beach - This is the central beach of Danang. The beach is bordered with a lot of resorts
            and hotels making it a very popular beach. One can indulge in a lot of fun activities lie para sailing,
            para gliding, snorkelling, banana boat rides, speed boat rides on this beach. The visitors can also
            taste authentic local cuisines from the shake which line up the beach. Danang also offers some of
            the most interesting markets for shopping.
              </p>
              <p className="dest-text">
              Ba Na hills - Just 20 kms from Danang down town lies this wonderful amusement park. On has to
            travel by a cablecar to reach the top these hills. The cablecar is one of the longest in Asia. The
            stunning views from the cable car as you ascend will take your breath away. The most iconic
            bridge of Vietnam, the Golden bridge is on top of these hills. The old fashioned French style
            village, gardens, cobblestone pathways, cute cafes offer some of the best photo ops for you.
              </p>
              <p className="dest-text">
              Hoi An - This ancient town is an exceptionally well-preserved example of a South-East Asian
            trading port dating from the 15th to the 19th century. A visit to this magical town will transport you
            to the yesteryears. The charming yellow town comes to life during the evenings when the entire
            town is lit with lanterns. You can either take a stroll around the town and on the bridge or take a
            boat tour on the lazy river. It will remeind you of the fairy tales you read during your younger days.
              </p>
              <div className="scroll-container mb-5">
                <BsArrowDownSquareFill
                  size={30}
                  onClick={() => scrollHandler(sec4)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container" ref={sec4}>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 col-xl-12">
            <div className="it-container data-container mt-5">
              <h2 className="bold mb-3 package-head">Cam Than village</h2>
              <p className="dest-text">
              The small fishing town lies on the way from Danang to Hoi An town. The lagoon bordered by the
            thick undergrowth of palm trees is a picture straight form a Hollywood movie. The lagoon suddenly
            opens up to the vast ocean where you see the fisherman busily fishing who the tourists are singing
            the dancing on the temporary floating platforms setup in the sea.
              </p>
              <h2 className="bold mb-3 package-head">Must Visit spots - Hoi Chi Minch</h2>
              <p className="dest-text">
              Cu Chi Tunnels - These tunnels are a vast network of underground tunnels that were once used by
            the Viet Cong soldiers during the Vietnam War. They are a man made marvel to escape the
            enemies. One visit here will make you understand why a powerful country likeUSA also lost its war
            to these brave soldiers of Vietnam. Only when you go underground to the second level, which is
            almost 7 feet below the ground, you will understand how difficult it would have been to live inside
            these tunnels during the war time. This place is not for the faint hearted!!
              </p>
              <div className="scroll-container mb-5">
                <BsArrowDownSquareFill
                  size={30}
                  onClick={() => scrollHandler(sec5)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container" ref={sec5}>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 col-xl-12">
            <div className="it-container data-container">
              <h2 className="bold package-head">Getting there</h2>
              <img
                src="/destinations/Vietnam airports.jpg"
                alt="vietnam-getting"
                className="weather-img"
              />
              <div className="scroll-container">
                <BsArrowDownSquareFill
                  size={30}
                  onClick={() => scrollHandler(sec6)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container" ref={sec6}>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 col-xl-12">
            <div className="it-container data-container">
              <img
                src="/destinations/Vietnam airport map.jpg"
                alt="vietnam-weather"
                className="weather-img"
              />
              <div className="scroll-container mt-3">
                <BsArrowDownSquareFill
                  size={30}
                  onClick={() => scrollHandler(sec7)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container" ref={sec7}>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 col-xl-12">
            <div className="it-container data-container mt-5">
              <h2 className="bold mb-3 package-head">
              Vietnam VISA formalities 
              </h2>
              <p className="dest-text">
              E-VISA is the available option for any person travelling to Kenya. Some countries are exempted
            from procuring a VISA. To apply for an eVISA you should click on this link.
            <a
                  href="https://evisa.xuatnhapcanh.gov.vn/trang-chu-ttdt"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  apply
                </a>
              </p>
              <div className="scroll-container">
                <BsArrowDownSquareFill
                  size={30}
                  onClick={() => scrollHandler(sec8)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="container" ref={sec8}>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 col-xl-12">
            <div className="it-container data-container">
              <h2 className="bold package-head">Weather in Vietnam  </h2>
              <p className='dest-text'>The best time to visit Vietnam is between <b>November and April</b>. The weather in Vietnam can be split
                by region. In Hanoi &amp; the north, May to October is hot and humid with high rainfall, November to
                April is cooler and dry. In the far north, December &amp; January can be particularly cold.</p>
              <div className="scroll-container">
                <BsArrowDownSquareFill
                  size={30}
                  onClick={() => scrollHandler(sec9)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={sec9} className="mb-5">
      <p className='destination-package-head mb-3 mt-5'>View Our Vietnam Packages</p>
          <div className="destinations-container mb-5">
            <div className="best-package-list">
                {vietnamPackages.map((pkg) => (
                        <div key={pkg.id} className="package-list-card mb-3">
                        <img
                            src={pkg.bannerImageUrl}
                            alt={pkg.destinationName}
                            className="list-card-img mb-3"
                        />
                        <div>
                            <h4 className="title-list mb-3">{pkg.destinationHead}</h4>
                            <h5 className="title-dates-list bold mb-3">{pkg.noOfDays}</h5>
                            <h5 className="title-rate-list bold mb-3">{pkg.cost}</h5>
                            <div className="btn-container-list-card">
                            <Link
                                to={`/package/${pkg.destinationName.replace(/\s+/g, "-")}`}
                                className="blogs-link"
                            >
                                <button className="view-button-list-card">
                                View Itinerary
                                </button>
                            </Link>
                            <Link to="/form" className="blogs-link">
                                <button className="view-button-list-card-book">
                                Book Now
                                </button>
                            </Link>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
                  </div>
            </div>
        <Footer />
      </HelmetProvider>
        </>
    );
}
export default DestinationVietnam;
