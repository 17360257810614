import React, { useEffect } from "react";
//import SouthAfricaCard from "../SouthAfricaCard";
import WildlifeCard from "../WildlifePackages";
import Navbar from "../Navbar";
import Footer from "../Contact";
import "./packages.css";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import HomeSlider from '../HomeSlider';

const images = [
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Packages%2F5.jpg?alt=media&token=df7f52a9-ba59-4833-bff3-28373a945a83",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Packages%2F7.jpg?alt=media&token=4859ee00-1ce1-4d83-916d-71ea75c7db9b",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-packages%2F9.webp?alt=media&token=090abad3-8d3f-4fde-8fd8-1356a0af79b3",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-africa%2Fsouth-africa-banner.webp?alt=media&token=e40c56a7-35b2-49b7-941d-b2b543a75658",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-africa%2Fa-5-4.webp?alt=media&token=293b54a1-ef7e-4b66-8975-03f0824a7c30",
]

const preloadImages = (imageUrls) => {
  imageUrls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};

const HotSellingPackages = () => {
  useEffect(() => {
    const allImages = [...images];
    preloadImages(allImages);
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Wildlife Packages by Travel Unbounded</title>
          <meta
            name="description"
            content="Discover extraordinary wildlife with Travel Unbounded. Our expert guides lead tailor-made safari tours, promising moments of awe and wonder. Book now for an immersive journey into nature's wonders."
          />
        </Helmet>
        <Navbar />
        <HomeSlider images={images} showOverlayText={false} />
        <h1 className="text-center package-head mt-5 mb-5">WILDLIFE PACKAGES</h1>
        <div className="combined-cards">
          <WildlifeCard />
        </div>
        <Footer />
      </HelmetProvider>
    </>
  );
};

export default HotSellingPackages;
