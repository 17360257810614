import React, {useState, useEffect} from 'react';
import Navbar from '../Navbar';
import Footer from '../Contact';
import HomeSlider from '../HomeSlider';
import {Link} from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import './index.css';

const images = [
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Packages%2F1.jpg?alt=media&token=73be1d82-5c46-48e4-8d39-20f61adaa80c",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Packages%2FGullfoss-Winter.jpg?alt=media&token=fffef5c5-b698-488d-b8e2-99c560817d6a",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Home%2F5.jpg?alt=media&token=c02ed538-b524-45c9-a94e-221a64603213",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Packages%2FReykjavi%CC%81k-Winter-Aerial.jpg?alt=media&token=7e689567-e195-4c5c-868f-63a1b497f31b"
]

const DestinationKerala = () => {
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        const fetchPackages = async () => {
          const db = firebase.firestore();
          try {
            const snapshot = await db.collection("PackageTemplate").get();
            const packageList = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPackages(packageList);
          } catch (error) {
            console.error("Error fetching packages:", error);
          } 
        };
    
        fetchPackages();
      }, []);

      const keralaPackages = packages.filter(pkg => 
        pkg.destinationName.toLowerCase().includes('iceland')
      );
    
    return (
        <div>
            <Navbar/>
            <HomeSlider images={images}/>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12 col-xl-12'>
                        <h1 className='home-head mt-5'>Iceland</h1>
                        <p className='destination-description'>Iceland, located in the North Atlantic, is known for its breathtaking landscapes, vibrant culture, and unique natural phenomena. The "Land of Fire and Ice" offers visitors an extraordinary blend of glaciers, volcanoes, geysers, and hot springs, making it a paradise for nature lovers and adventure seekers.</p>
                        <p className='destination-sub-head mt-5'>Geography</p>
                        <p className='destination-description'>Iceland’s geography is shaped by volcanic and glacial activity. Its landscape features active volcanoes, vast glaciers, lava fields, waterfalls, and geothermal springs. The country’s dramatic natural formations, such as the Golden Circle and the Blue Lagoon, make it a prime destination for exploration.</p>
                        <p className='destination-sub-head mt-5'>International Airports in Iceland</p>
                        <p className='destination-description'>Keflavík International Airport (KEF), near Reykjavik, is the primary international gateway into Iceland.</p>
                        <p className='destination-description'>Akureyri Airport (AEY) is a regional airport serving northern Iceland.</p>
                        <p className='destination-sub-head mt-5'>Climate</p>
                        <p className='destination-description'><b>Summer (June to August):</b> Mild temperatures with long daylight hours, making it an ideal time to explore Iceland’s outdoors.</p>
                        <p className='destination-description'><b>Winter (November to March):</b> Cold and snowy, but a great time to witness the Northern Lights (Aurora Borealis).</p>
                        <p className='destination-description'><b>Spring and Fall:</b> Transitional seasons with moderate temperatures and fewer tourists.</p>
                        <p className='destination-sub-head mt-5'>Culture</p>
                        <p className='destination-description'><b>Language:</b> Icelandic is the official language, but English is widely spoken.</p>
                        <p className='destination-description'><b>Festivals:</b> Popular festivals include Reykjavík Culture Night, Iceland Airwaves (music festival), and Þorrablót (mid-winter feast).</p>
                        <p className='destination-description'><b>Arts and Crafts:</b> Iceland is known for its woolen goods, ceramics, and literature, with a strong tradition of sagas.</p>
                        <p className='destination-sub-head mt-5'>VISA formalities</p>
                        <p className='destination-description'>Most visitors can enter Iceland visa-free for short stays. However, travelers from certain countries may require a Schengen visa. For visa information,
                            <a href='https://island.is/s/utlendingastofnun' target='__blank'>Check Icelandic Directorate of Immigration website.</a>
                        </p>
                        <p className='destination-sub-head mt-5'>Tourism</p>
                        <p className='destination-description'>Here are some key tourist locations and attractions in Iceland:</p>
                        <p className='destination-sub-head'>1.Reykjavik</p>
                        <p className='destination-description'><b>Hallgrímskirkja:</b> A unique architectural church offering panoramic views of Reykjavik.</p>
                        <p className='destination-description'><b>Harpa Concert Hall:</b> Known for its striking glass design and cultural events.</p>
                        <p className='destination-description'><b>Blue Lagoon:</b> A famous geothermal spa located near Reykjavik.</p>
                        <p className='destination-sub-head'>2.Golden Circle</p>
                        <p className='destination-description'><b>Thingvellir National Park:</b> A UNESCO World Heritage site where you can see the meeting point of the North American and Eurasian tectonic plates.</p>
                        <p className='destination-description'><b>Geysir Geothermal Area:</b> Home to the famous Strokkur geyser.</p>
                        <p className='destination-description'><b>Gullfoss Waterfall:</b> One of Iceland's most iconic waterfalls.</p>
                        <p className='destination-sub-head'>3.South Coast</p>
                        <p className='destination-description'><b>Seljalandsfoss and Skógafoss Waterfalls:</b> Majestic waterfalls with dramatic backdrops.</p>
                        <p className='destination-description'><b>Reynisfjara Black Sand Beach:</b> Known for its basalt columns and rough surf.</p>
                        <p className='destination-description'><b>Jökulsárlón Glacier Lagoon:</b> A breathtaking lagoon filled with floating icebergs.</p>
                        <p className='destination-sub-head'>4.Northern Iceland</p>
                        <p className='destination-description'><b>Akureyri:</b> A vibrant town known for its beautiful fjords and botanical gardens.</p>
                        <p className='destination-description'><b>Goðafoss Waterfall:</b> Also known as the "Waterfall of the Gods."</p>
                        <p className='destination-description'><b>Lake Mývatn:</b> Famous for its geothermal features and birdlife.</p>
                        <p className='destination-sub-head'>5.Westfjords</p>
                        <p className='destination-description'><b>Dynjandi Waterfall:</b> A series of waterfalls cascading down a mountain.</p>
                        <p className='destination-description'><b>Hornstrandir Nature Reserve:</b> A remote area ideal for hiking and wildlife watching.</p>
                        <p className='destination-sub-head'>6.Adventure Activities:</p>
                        <p className='destination-description'><b>Trekking:</b> Laugavegur Trail, Fimmvörðuháls Trek.</p>
                        <p className='destination-description'><b>Glacier Hiking:</b> Vatnajökull and Snæfellsjökull glaciers.</p>
                        <p className='destination-description'><b>Whale Watching:</b> Husavik and Reykjavik offer great whale-watching tours.</p>
                        <p className='destination-description'><b>Northern Lights:</b> Best viewed during winter in remote areas like Thingvellir National Park or near Akureyri.</p>
                        </div>
                    </div>
                </div>
                <p className='destination-package-head mb-3 mt-5'>View Our Iceland Packages</p>
                    <div className="destinations-container mb-5">
                    <div className="best-package-list">
                    {keralaPackages.map((pkg) => (
                        <div key={pkg.id} className="package-list-card mb-3">
                        <img
                            src={pkg.bannerImageUrl}
                            alt={pkg.destinationName}
                            className="list-card-img mb-3"
                        />
                        <div>
                            <h4 className="title-list mb-3">{pkg.destinationHead}</h4>
                            <h5 className="title-dates-list bold mb-3">{pkg.noOfDays}</h5>
                            <h5 className="title-rate-list bold mb-3">{pkg.cost}</h5>
                            <div className="btn-container-list-card">
                            <Link
                                to={`/package/${pkg.destinationName.replace(/\s+/g, "-")}`}
                                className="blogs-link"
                            >
                                <button className="view-button-list-card">
                                View Itinerary
                                </button>
                            </Link>
                            <Link to="/form" className="blogs-link">
                                <button className="view-button-list-card-book">
                                Book Now
                                </button>
                            </Link>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
            </div>
            <Footer/>
        </div>
    );
}

export default DestinationKerala;
