import React, {useState} from 'react';
import { FaWhatsapp, FaInstagram, FaYoutube, FaPinterest, FaFacebookF, FaLinkedinIn} from "react-icons/fa";
import { FaMedium } from "react-icons/fa6";
import {
    AiOutlineCopyrightCircle,
    AiOutlineMail,
  } from "react-icons/ai";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { toast, Toaster } from "react-hot-toast";
import {Link} from 'react-router-dom';
import './index.css';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
  };
  
  firebase.initializeApp(firebaseConfig);
  
  const db = firebase.firestore();

const Contact = () => {
    const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await db.collection("news-letter").add({
        email: email,
        time: firebase.firestore.FieldValue.serverTimestamp(),
      });
      toast.success("Subscribed to News Letter Successfully");
      setEmail("");
      console.log("Email added successfully");
    } catch (error) {
      console.error("Error adding email to Firestore:", error);
      toast.error("Please enter valid mail");
    }
  };

    return (
        <>
         <Toaster toastOptions={{ duration: 5000 }} />
         <div className="newsletter-container">
            <div className="newsletter-content">
                <div className="newsletter-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bell">
                        <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"/>
                        <path d="M13.73 21a2 2 0 0 1-3.46 0"/>
                    </svg>
                </div>
            <div className="newsletter-text">
                <p className='news-letter-head'>Get More Updates</p>
                <p className='news-letter-text'>Subscribe to our News Letter</p>
            </div>
        </div>
            <div className="newsletter-form">
                <form onSubmit={handleSubmit}>
                    <input type="email" placeholder="Your Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    <button>SUBSCRIBE</button>
                </form>
            </div>
        </div> 
            <div className='contact-section'>
                <div className='contact-container'>
                    <div className="office-info">
                        <h3>Registered office</h3>
                        <hr />
                        <p>No 10, 8th Main, 12th Cross, Vinayaka Nagar B block, Konene Agrahara, Bangalore - 560017</p>
                    </div>
                    <div className="office-info">
                        <h3>Headquarters</h3>
                        <hr />
                        <p>No 32, Global Incubation Services, CA Site, No 1, HAL 3rd Stage EXTN, behind Hotel Leela Palace, HAL 2nd Stage, Kodihalli, Bengaluru, Karnataka - 560008</p>
                    </div>
                    <div className="office-info">
                        <h3>Nairobi office</h3>
                        <hr />
                        <p>Westpark Towers, Muthithi Road, Nairobi, P.O. Box: 6950 Postal Code - 00100</p>
                    </div>
                </div>
                <div className='contact-container'>
                    <div className="office-info">
                        <h3>Kerala Office</h3>
                        <hr />
                        <p>39/2475-B1, Suite 80, LR Towers, SJRRA 104, S Janatha Road, Palavivatton, Kochi, Ernakulam, Kerala - 682025</p>
                    </div>
                    <div className="office-info">
                        <h3>Tamil Nadu Office</h3>
                        <hr />
                        <p>The workvilla old no 669 new no 306, kamala arcade, Thousand
                        lights, Anna Salai, Chennai, Chennai, Tamil Nadu - 600006</p>
                    </div>
                    <div className="office-info">
                        <h3>Goa Office</h3>
                        <hr />
                        <p>No. 66, Phase IV, Verna Industrial Estate, Verna, Goa, South Goa, Goa - 403722</p>
                    </div>
                </div>
                <div className='contact-details'>
                    <div className='office-info'>
                        <h3 className='text-center'>Contact Us</h3>
                        <hr/>
                        <p className='contact-text text-center'><FaWhatsapp size={20}/> 8792273808<br/>
                        <AiOutlineMail size={20}/> support@travelunbounded.com</p>
                    </div>
                    <div className='logo-info-contact'>
                        <img
                            src="/infy-logo.jpg"
                            alt='logo' 
                            className='review' />
                        <h3>Travel Unbounded</h3>
                    </div>
                    <div className='office-info'>
                        <h3 className='text-center'>Follow Us</h3>
                        <hr/>
                  <div className="icons-container-footer mt-3">
                  <a href="https://www.facebook.com/travelunboundedcom" target="__blank" aria-label="Facebook">
                    <div className="nav-icon-footer text" style={{ color: 'white', backgroundColor: "#1877F2" }}>
                      <FaFacebookF size={24} />
                    </div>
                  </a>
                  <a href="https://www.instagram.com/travel_ub/" target="__blank" aria-label="Instagram">
                    <div className="nav-icon-footer text" style={{ color: 'white', transition: "background-color 0.3s ease", background: "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)" }}>
                      <FaInstagram size={24} />
                    </div>
                  </a>
                  <a href="https://www.linkedin.com/company/travel-unbounded/" target="__blank" aria-label="LinkedIn">
                    <div className="nav-icon-footer text" style={{ color: 'white', backgroundColor: "#0077B5" }}>
                      <FaLinkedinIn size={24} />
                    </div>
                  </a>
                  <a href="https://www.youtube.com/@travelunbounded668/" target="__blank" aria-label="YouTube">
                    <div className="nav-icon-footer text" style={{ color: 'white', backgroundColor: "#FF0000" }}>
                      <FaYoutube size={24} />
                    </div>
                  </a>
                  <a href="https://wa.me/8792273808/" target="__blank" aria-label="WhatsApp">
                    <div className="nav-icon-footer text" style={{ color: 'white', backgroundColor: "#25D366" }}>
                      <FaWhatsapp size={24} />
                    </div>
                  </a>
                  <a href="https://in.pinterest.com/TUB2014/" target="__blank" aria-label="Pinterest">
                    <div className="nav-icon-footer text" style={{ color: 'white', backgroundColor: "#E60023" }}>
                      <FaPinterest size={24} />
                    </div>
                  </a>
                  <a href="https://medium.com/@Travel_ub" target="__blank" aria-label="Medium">
                    <div className="nav-icon-footer text" style={{ color: 'white', backgroundColor: "#000000" }}>
                      <FaMedium size={24} />
                    </div>
                  </a>
                </div>
                </div>
            </div>
        </div> 
        <div className='contact-footer-container'>
            <div>
            <p className='mt-2 contact-footer-text'><AiOutlineCopyrightCircle /> All rights reserved to Travel Unbounded@2024</p>
            </div>
            <div>
            <div className='contact-row'>
            <Link to="/privacy" className="link">
              <p className="m-2 contact-footer-text">Privacy policy </p>
            </Link>
            <Link to="/terms" className="link">
              <p className="m-2 contact-footer-text">Terms and conditions </p>
            </Link>
            <Link to="/cancellation-refund-policy" className="link">
              <p className="m-2 contact-footer-text">Cancellation & Refund Policy </p>
            </Link>
            <a
              href="https://www.google.com/search?q=travel+unbounded&rlz=1C1UEAD_enIN1077IN1077&oq=travel&gs_lcrp=EgZjaHJvbWUqBggCEEUYOzIGCAAQRRg8MgwIARBFGDkYsQMYgAQyBggCEEUYOzIGCAMQRRg9MgYIBBBFGDwyBggFEEUYPTIGCAYQRRg8MgYIBxBFGDzSAQg1NDgyajBqN6gCALACAA&sourceid=chrome&ie=UTF-8#lrd=0x3bae1405d293a9a1:0x2593b16dbea01fb6,3,,,,"
              className="link"
              target="__blank"
            >
              <p className="m-2 contact-footer-text">Write a review</p>
            </a>
            </div>
            </div>
        </div>
        </>
    );
}

export default Contact;
