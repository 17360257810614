import React, { useState } from 'react';
import './index.css';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { toast, Toaster } from "react-hot-toast";

const firebaseConfig = {
    apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
    authDomain: "phone-auth-d0a8d.firebaseapp.com",
    projectId: "phone-auth-d0a8d",
    storageBucket: "phone-auth-d0a8d.appspot.com",
    messagingSenderId: "309252587015",
    appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const AddPackageForm = () => {
  const [bannerImage, setBannerImage] = useState(null);
  const [formData, setFormData] = useState({
    destinationName: '',
    title:'',
    description:'',
    destinationHead:'',
    travelDates:'',
    isForeign: false,
    isOffer: false,
    offerText:'',
    aboutDestination: [''],
    cost:'',
    noOfDays:'',
    bestTimeToTravel: '',
    nearestAirport: '',
    nearestRailwayStation: '',
    duration: '',
    contact:'',
    email:'',
    itineraryTitles: Array(15).fill(''),
    shortItinerary: Array(15).fill({ day: '', paragraphs: [] }),
    inclusions: Array(15).fill(''),
    exclusions: Array(15).fill(''),
    paymentTerms: Array(10).fill(''),
    cancellationTerms: Array(10).fill('')
  });

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setBannerImage(e.target.files[0]);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleArrayChange = (e, index, arrayName) => {
    const newArray = [...formData[arrayName]];
    newArray[index] = e.target.value;
    setFormData({ ...formData, [arrayName]: newArray });
  };

  const handleParagraphChange = (e, dayIndex, paragraphIndex) => {
    const newItinerary = formData.shortItinerary.map((day, i) => {
      if (i === dayIndex) {
        const updatedParagraphs = [...day.paragraphs];
        updatedParagraphs[paragraphIndex] = e.target.value;
        return { ...day, paragraphs: updatedParagraphs };
      }
      return day;
    });
    setFormData({ ...formData, shortItinerary: newItinerary });
  };

  const addParagraph = (dayIndex) => {
    const newItinerary = formData.shortItinerary.map((day, i) => {
      if (i === dayIndex) {
        return { ...day, paragraphs: [...day.paragraphs, ''] };
      }
      return day;
    });
    setFormData({ ...formData, shortItinerary: newItinerary });
  };

  const handleAboutDestinationChange = (e, index) => {
    const newAboutDestination = [...formData.aboutDestination];
    newAboutDestination[index] = e.target.value;
    setFormData({ ...formData, aboutDestination: newAboutDestination });
  };

  const addAboutParagraph = () => {
    setFormData({ ...formData, aboutDestination: [...formData.aboutDestination, ''] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const nonEmptyItineraryTitles = formData.itineraryTitles.filter(item => item.trim() !== '');
    const nonEmptyShortItinerary = formData.shortItinerary.filter(day => day.paragraphs.length > 0 && day.paragraphs.some(p => p.trim() !== ''));
    const nonEmptyInclusions = formData.inclusions.filter(item => item.trim() !== '');
    const nonEmptyExclusions = formData.exclusions.filter(item => item.trim() !== '');
    const nonEmptyPaymentTerms = formData.paymentTerms.filter(item => item.trim() !== '');
    const nonEmptyCancellationTerms = formData.cancellationTerms.filter(item => item.trim() !== '');

    if (bannerImage) {
      const storageRef = firebase.storage().ref();
      const bannerImageRef = storageRef.child(`packageTemplate/${bannerImage.name}`);
      await bannerImageRef.put(bannerImage);
      const bannerImageUrl = await bannerImageRef.getDownloadURL();

      const db = firebase.firestore();
      await db.collection('PackageTemplate').doc(formData.destinationName).set({
        ...formData,
        bannerImageUrl,
        itineraryTitles:nonEmptyItineraryTitles,
        shortItinerary:nonEmptyShortItinerary,
        inclusions:nonEmptyInclusions,
        exclusions:nonEmptyExclusions,
        paymentTerms: nonEmptyPaymentTerms,
        cancellationTerms: nonEmptyCancellationTerms
      });

      toast.success('Package added successfully!');
      setBannerImage(null);
      setFormData({
        destinationName: '',
        title:'',
        description:'',
        destinationHead:'',
        travelDates:'',
        isForeign: false,
        isOffer:false,
        offerText:'',
        aboutDestination: [''],
        cost:'',
        noOfDays:'',
        bestTimeToTravel: '',
        nearestAirport: '',
        nearestRailwayStation: '',
        duration: '',
        contact:'',
        email:'',
        itineraryTitles: Array(15).fill(''),
        shortItinerary: Array(15).fill({ day: '', paragraphs: [] }),
        inclusions: Array(15).fill(''),
        exclusions: Array(15).fill(''),
        paymentTerms: Array(10).fill(''),
        cancellationTerms: Array(10).fill('')
      });
    } else {
      toast.error('Please select a banner image');
    }
  };

  return (
    <>
      <Toaster toastOptions={{ duration: 5000 }} style={{ fontStyle: 'Canva Sans', color: 'black' }} />
      <h1 className='mt-3 font text-center' style={{fontFamily:'Lora'}}>Add a Package</h1>
      <form className="container mt-3" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Banner Image:</label>
          <input type="file" className="form-control-file" onChange={handleImageChange} />
        </div>
        <div className="form-group">
          <label>Destination Name:</label>
          <input type="text" name="destinationName" className="form-control" value={formData.destinationName} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Meta Title:</label>
          <input type="text" name="title" className="form-control" value={formData.title} onChange={handleChange} />
          <p style={{color:'green'}}>*The title is displayed on google</p>
        </div>
        <div className="form-group">
          <label>Meta Description:</label>
          <input type="text" name="description" className="form-control" value={formData.description} onChange={handleChange} />
          <p style={{color:'green'}}>*The description is displayed on google</p>
        </div>
        <div className="form-group">
          <label>Destination Heading:</label>
          <input type="text" name="destinationHead" className="form-control" value={formData.destinationHead} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Travel Dates:</label>
          <input type="text" name="travelDates" className="form-control" value={formData.travelDates} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Foreign package:</label>
          <input type="boolean" name="isForeign" className="form-control" value={formData.isForeign} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Offer available:</label>
          <input type="boolean" name="isOffer" className="form-control" value={formData.isOffer} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Offer Text:</label>
          <input type="text" name="offerText" className="form-control" value={formData.offerText} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>About Destination:</label>
          {formData.aboutDestination.map((paragraph, index) => (
            <div key={index} className="mb-2">
              <textarea
                className="form-control"
                value={paragraph}
                onChange={(e) => handleAboutDestinationChange(e, index)}
                placeholder={`Paragraph ${index + 1}`}
              />
            </div>
          ))}
          <button
            type="button"
            className="btn btn-secondary mb-3"
            onClick={addAboutParagraph}
          >
            Add Paragraph
          </button>
        </div>
        <div className="form-group">
          <label>Cost:</label>
          <input type="text" name="cost" className="form-control" value={formData.cost} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Number of Days:</label>
          <input type="text" name="noOfDays" className="form-control" value={formData.noOfDays} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Best Time to Travel:</label>
          <input type="text" name="bestTimeToTravel" className="form-control" value={formData.bestTimeToTravel} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Nearest Airport:</label>
          <input type="text" name="nearestAirport" className="form-control" value={formData.nearestAirport} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Nearest Railway Station:</label>
          <input type="text" name="nearestRailwayStation" className="form-control" value={formData.nearestRailwayStation} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Duration:</label>
          <input type="text" name="duration" className="form-control" value={formData.duration} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Contact No:</label>
          <input type="text" name="contact" className="form-control" value={formData.contact} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Email:</label>
          <input type="text" name="email" className="form-control" value={formData.email} onChange={handleChange} />
        </div>

        {formData.shortItinerary.map((dayItinerary, dayIndex) => (
          <div key={dayIndex}>
            <div className="form-group">
              <label>{`Day ${dayIndex + 1} Title:`}</label>
              <input
                type="text"
                className="form-control"
                value={formData.itineraryTitles[dayIndex]}
                onChange={(e) => handleArrayChange(e, dayIndex, 'itineraryTitles')}
              />
            </div>
            {dayItinerary.paragraphs.map((paragraph, paragraphIndex) => (
              <div className="form-group" key={paragraphIndex}>
                <label>{`Day ${dayIndex + 1} Itinerary Paragraph ${paragraphIndex + 1}:`}</label>
                <textarea
                  className="form-control"
                  value={paragraph}
                  onChange={(e) => handleParagraphChange(e, dayIndex, paragraphIndex)}
                />
              </div>
            ))}
            <button
              type="button"
              className="btn btn-secondary mb-2"
              onClick={() => addParagraph(dayIndex)}
            >
              Add Paragraph for Day {dayIndex + 1}
            </button>
          </div>
        ))}

        <div className="form-group">
          <label>Inclusions:</label>
          {formData.inclusions.map((item, index) => (
            <input
              type='text'
              key={index}
              className="form-control mb-2"
              placeholder={`Inclusion ${index + 1}`}
              value={item}
              onChange={(e) => handleArrayChange(e, index, 'inclusions')}
            />
          ))}
        </div>

        <div className="form-group">
          <label>Exclusions:</label>
          {formData.exclusions.map((item, index) => (
            <input
              type='text'
              key={index}
              className="form-control mb-2"
              placeholder={`Exclusion ${index + 1}`}
              value={item}
              onChange={(e) => handleArrayChange(e, index, 'exclusions')}
            />
          ))}
        </div>

        <div className="form-group">
          <label>Payment Terms:</label>
          {formData.paymentTerms.map((item, index) => (
            <input
              type='text'
              key={index}
              className="form-control mb-2"
              placeholder={`Payment Term ${index + 1}`}
              value={item}
              onChange={(e) => handleArrayChange(e, index, 'paymentTerms')}
            />
          ))}
        </div>

        <div className="form-group">
          <label>Cancellation Terms:</label>
          {formData.cancellationTerms.map((item, index) => (
            <input
              type='text'
              key={index}
              className="form-control mb-2"
              placeholder={`Cancellation Term ${index + 1}`}
              value={item}
              onChange={(e) => handleArrayChange(e, index, 'cancellationTerms')}
            />
          ))}
        </div>

        <button type="submit" className="btn btn-primary mb-3">Submit</button>
      </form>
    </>
  );
};

export default AddPackageForm;
