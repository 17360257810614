import React, { useState } from "react";
import { FaBars, FaTimes, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Link, NavLink, useLocation } from 'react-router-dom';
import { MdHome, MdPlace, MdAddCall} from "react-icons/md";
import { LuPackagePlus } from "react-icons/lu";
import { FaEarthAfrica } from "react-icons/fa6";
import { FaBlog } from "react-icons/fa";
import { MdContactSupport } from "react-icons/md";
import { FaInstagram} from "react-icons/fa";
import { FaYoutube, FaWhatsapp, FaFacebookF, FaLinkedinIn} from "react-icons/fa";
import './nav.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const location = useLocation();
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const destinationRoutes = [
    '/kerala',
    '/himachal',
    '/bandipur',
    '/kabini',
    '/corbett',
    '/ranthambore',
    '/bandhavgarh',
    '/kenya',
    '/vietnam',
    '/tanzania',
    '/andaman',
    '/iceland',
  ];
  const blogRoutes = [
    '/blog/'
  ]

  const leisurePackages = ['kerala', 'spiti', 'lahaul', 'vietnam', 'iceland', 'shimla','andaman'];
  const wildlifePackages = ['bandipur', 'bandhavgarh', 'corbett', 'kabini', 'ranthambore', 'kenya', 'pench', 'south-africa'];

  const currentPath = location.pathname.toLowerCase();

  const matchPackageInPath = (packagesArray) => {
    return packagesArray.some(pkg => currentPath.startsWith('/package/') && currentPath.includes(pkg.toLowerCase()));
  };
  
  const isLeisureActive = matchPackageInPath(leisurePackages);
  const isWildlifeActive = matchPackageInPath(wildlifePackages);


  const isBlogActive = blogRoutes.some(route => location.pathname.startsWith(route));
  const isDestinationsActive = destinationRoutes.includes(location.pathname);

  return (
    <header className="navbar-tu">
      <div className="logo-tu">
        <Link to='/'>
        <img
          src="/logo.png"
          alt="logo"
          className="logo-img-tu"
        />
        </Link>
      </div>
      <div>
      <ul className="nav-items-tu">
      <li><NavLink to='/' className="nav-tu-link" activeclassname="active">HOME</NavLink></li>
        <li className={`dropdown-tu ${isDestinationsActive ? 'active' : ''}`}>
          <div className="dropdown-head" onClick={toggleDropdown}>
            DESTINATIONS
            {isDropdownOpen ? <FaChevronUp className="dropdown-icon" /> : <FaChevronDown className="dropdown-icon" />}
          </div>
          {isDropdownOpen && (
            <div className="dropdown-content-tu">
              <div className="dropdown-row">
                <div className="dropdown-column">
                  <h4 className="text-center">India</h4>
                  <ul>
                    <li><NavLink to="/kerala" className="nav-tu-link" activeclassname="active">Kerala</NavLink></li>
                    <li><NavLink to="/himachal" className="nav-tu-link" activeclassname="active">Himachal</NavLink></li>
                    <li><NavLink to="/andaman" className="nav-tu-link" activeclassname="active">Andaman</NavLink></li>
                    <li><NavLink to="/bandipur" className="nav-tu-link" activeclassname="active">Bandipur</NavLink></li>
                    <li><NavLink to="/kabini" className="nav-tu-link" activeclassname="active">Kabini</NavLink></li>
                    <li><NavLink to="/corbett" className="nav-tu-link" activeclassname="active">Corbett</NavLink></li>
                    <li><NavLink to="/ranthambore" className="nav-tu-link" activeclassname="active">Ranthambore</NavLink></li>
                    <li><NavLink to="/bandhavgarh" className="nav-tu-link" activeclassname="active">Bandhavgarh</NavLink></li>
                  </ul>
                </div>
                <div className="dropdown-column">
                  <h4 className="text-center">International</h4>
                  <ul>
                    <li><NavLink to="/kenya" className="nav-tu-link" activeclassname="active">Kenya</NavLink></li>
                    <li><NavLink to="/vietnam" className="nav-tu-link" activeclassname="active">Vietnam</NavLink></li>
                    <li><NavLink to="/iceland" className="nav-tu-link" activeclassname="active">Iceland</NavLink></li>
                    <li><NavLink to="/tanzania" className="nav-tu-link" activeclassname="active">Tanzania</NavLink></li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </li>
        <li><NavLink to="/leisure-packages" className={`nav-tu-link ${isLeisureActive ? 'active' : ''}`} activeclassname="active">LEISURE PACKAGES</NavLink></li>
        <li><NavLink to="/wildlife-packages" className={`nav-tu-link ${isWildlifeActive ? 'active' : ''}`}>WILDLIFE PACKAGES</NavLink></li>
        <li><NavLink to="/blogs" className={`nav-tu-link ${isBlogActive ? 'active' : ''}`} activeclassname="active">BLOGS</NavLink></li>
        <li><NavLink to="/about" className="nav-tu-link" activeclassname="active">ABOUT US</NavLink></li>
        <li><NavLink to="/contact" className="nav-tu-link" activeclassname="active">CONTACT US</NavLink></li>
        </ul>
        </div>
        <div className="nav-btn-container-tu">
          <Link to="/form" className="nav-tu-link">
            <button type="button" className="nav-btn-tu" aria-label="Book Now">
              Book Now
            </button>
          </Link>
        </div>
      <div className="package-btn-tu-container">
      <Link to="/leisure-packages" className="nav-tu-link">
        <div className="package-btn-tu">
         View Packages
        </div>
      </Link>
    </div>
      <div className="menu-icon-tu-nav" onClick={toggleSidebar}>
        {isOpen ? <FaTimes className="menu-icon-nav" /> : <FaBars className="menu-icon-nav" />}
      </div>
      <nav className={`sidebar-tu ${isOpen ? "open" : ""}`}>
        <ul>
        <li><NavLink to="/" className="nav-tu-link" activeclassname="active"><MdHome size={20}/>  HOME</NavLink></li>
          <hr />
          <li className={`dropdown-tu ${isDestinationsActive ? 'active' : ''}`}>
            <div className="dropdown-head" onClick={toggleDropdown}>
            <MdPlace size={20}/>  DESTINATIONS
              {isDropdownOpen ? <FaChevronUp className="dropdown-icon" /> : <FaChevronDown className="dropdown-icon" />}
            </div>
            {isDropdownOpen && (
              <div className="sidebar-dropdown-content mt-3">
                <div className="sidebar-dropdown-column">
                <h4 className="text-center">India</h4>
                  <ul>
                    <li><NavLink to="/kerala" className="nav-tu-link" activeclassname="active">Kerala</NavLink></li>
                    <li><NavLink to="/himachal" className="nav-tu-link" activeclassname="active">Himachal</NavLink></li>
                    <li><NavLink to="/andaman" className="nav-tu-link" activeclassname="active">Andaman</NavLink></li>
                    <li><NavLink to="/bandipur" className="nav-tu-link" activeclassname="active">Bandipur</NavLink></li>
                    <li><NavLink to="/kabini" className="nav-tu-link" activeclassname="active">Kabini</NavLink></li>
                    <li><NavLink to="/corbett" className="nav-tu-link" activeclassname="active">Corbett</NavLink></li>
                    <li><NavLink to="/ranthambore" className="nav-tu-link" activeclassname="active">Ranthambore</NavLink></li>
                    <li><NavLink to="/bandhavgarh" className="nav-tu-link" activeclassname="active">Bandhavgarh</NavLink></li>
                  </ul>
                </div>
                <div className="sidebar-dropdown-column">
                  <h4 className="text-center">International</h4>
                  <ul>
                    <li><NavLink to="/kenya" className="nav-tu-link" activeclassname="active">Kenya</NavLink></li>
                    <li><NavLink to="/vietnam" className="nav-tu-link" activeclassname="active">Vietnam</NavLink></li>
                    <li><NavLink to="/iceland" className="nav-tu-link" activeclassname="active">Iceland</NavLink></li>
                    <li><NavLink to="/tanzania" className="nav-tu-link" activeclassname="active">Tanzania</NavLink></li>
                  </ul>
                </div>
              </div>
            )}
          </li>
          <hr />
          <li><NavLink to="/leisure-packages" className={`nav-tu-link ${isLeisureActive ? 'active' : ''}`} activeclassname="active"><LuPackagePlus size={20}/> LEISURE PACKAGES</NavLink></li>
          <hr />
          <li><NavLink to="/wildlife-packages" className={`nav-tu-link ${isWildlifeActive ? 'active' : ''}`} activeclassname="active"><FaEarthAfrica />  WILDLIFE PACKAGES</NavLink></li>
          <hr />
          <li><NavLink to="/blogs" className={`nav-tu-link ${isBlogActive ? 'active' : ''}`} activeclassname="active"><FaBlog size={20}/>  BLOGS</NavLink></li>
          <hr />
          <li><NavLink to="/about" className="nav-tu-link" activeclassname="active"><MdContactSupport size={20}/>  ABOUT US</NavLink></li>
          <hr />
          <li><NavLink to="/contact" className="nav-tu-link" activeclassname="active"><MdAddCall size={20}/>  CONTACT US</NavLink></li>
          <hr />
        <div style={{paddingLeft: '15px', paddingTop:'10px', paddingBottom:'10px'}}>
        <div className="icons-container-footer mt-3">
            <a href="https://www.facebook.com/travelunboundedcom" target="__blank" aria-label="Facebook">
              <div className="nav-icon-footer text" style={{ color: 'white', backgroundColor: "#1877F2", marginLeft:'5px' }}>
                <FaFacebookF size={24} />
              </div>
            </a>
            <a href="https://www.instagram.com/travel_ub/" target="__blank" aria-label="Instagram">
              <div className="nav-icon-footer text" style={{ color: 'white', transition: "background-color 0.3s ease", background: "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)", marginLeft:'5px' }}>
                <FaInstagram size={24} />
              </div>
            </a>
            <a href="https://www.linkedin.com/company/travel-unbounded/" target="__blank" aria-label="LinkedIn">
              <div className="nav-icon-footer text" style={{ color: 'white', backgroundColor: "#0077B5", marginLeft:'5px' }}>
                <FaLinkedinIn size={24} />
              </div>
            </a>
            <a href="https://www.youtube.com/@travelunbounded668/" target="__blank" aria-label="YouTube">
              <div className="nav-icon-footer text" style={{ color: 'white', backgroundColor: "#FF0000", marginLeft:'5px' }}>
                <FaYoutube size={24} />
              </div>
            </a>
            <a href="https://wa.me/8792273808/" target="__blank" aria-label="WhatsApp">
              <div className="nav-icon-footer text" style={{ color: 'white', backgroundColor: "#25D366", marginLeft:'5px' }}>
                <FaWhatsapp size={24} />
              </div>
            </a>
          </div>
        </div>
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;
