import React, {useState, useEffect} from 'react';
import Navbar from '../Navbar';
import Footer from '../Contact';
import HomeSlider from '../HomeSlider';
import {Link} from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import './index.css';

const images = [
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/packageTemplate%2FMunnar%20copy.jpg?alt=media&token=fdea9716-7ad6-46ed-86f5-ff0356328e7d",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Home%2Fkerala-2.jpg?alt=media&token=62f58e36-bca0-4429-9709-da3f78ffda69",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Home%2Fkerala-3.jpg?alt=media&token=98837377-657f-44ea-a880-79398a18f837"
]

const DestinationKerala = () => {
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        const fetchPackages = async () => {
          const db = firebase.firestore();
          try {
            const snapshot = await db.collection("PackageTemplate").get();
            const packageList = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPackages(packageList);
          } catch (error) {
            console.error("Error fetching packages:", error);
          } 
        };
    
        fetchPackages();
      }, []);

      const keralaPackages = packages.filter(pkg => 
        pkg.destinationName.toLowerCase().includes('kerala')
      );
    
    return (
        <div>
            <Navbar/>
            <HomeSlider images={images}/>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12 col-xl-12'>
                        <h1 className='home-head mt-5'>Kerala</h1>
                        <p className='destination-description'>Kerala is a state in southern India known for its lush landscapes, unique culture, and high
                        quality of life. Kerala offers a unique blend of natural beauty, cultural richness, and modern
                        amenities, making it a fascinating place to visit or live.</p>
                        <p className='destination-sub-head mt-5'>Geography</p>
                        <p className='destination-description'>Kerala offers a unique blend of natural beauty, cultural richness, and modern amenities,
                        making it a fascinating place to visit.</p>
                        <p className='destination-sub-head mt-5'>International Airports in Kerala</p>
                        <p className='destination-description'>Kerala, a popular state in southern India, is served by two major international airports that cater to both domestic and international travelers:</p>
                        <p className='destination-description'><b>Cochin International Airport:</b></p>
                        <p className='destination-description'>Cochin International Airport is the busiest airport in Kerala and one of the largest in South India. It is renowned for being the first airport in the world to be fully powered by solar energy. The airport serves as a major gateway for travelers to the central and northern parts of Kerala and offers a wide range of international flights, connecting the region with key global destinations.</p>
                        <p className='destination-description'><b>Trivandrum International Airport:</b></p>
                        <p className='destination-description'>Trivandrum International Airport is the southernmost international airport in Kerala, serving the capital city of Thiruvananthapuram and its surrounding areas. It is an important hub for both international and domestic flights and has recently undergone significant upgrades to enhance passenger experience. The airport provides connections to major cities in the Middle East, Europe, and other international locations.</p>
                        <p className='destination-sub-head mt-5'>Climate</p>
                        <p className='destination-description'>Kerala has a tropical climate, characterized by heavy monsoon rains from June to
                        September and a warm, humid atmosphere throughout the year. The state&#39;s climate
                        supports its abundant flora and fauna.</p>
                        <p className='destination-sub-head mt-5'>Culture</p>
                        <p className='destination-description'>Kerala has a rich cultural heritage with influences from various traditions and communities.
                        It is famous for its classical dance forms like Kathakali and Mohiniyattam, as well as its
                        vibrant festivals such as Onam and Vishu.</p>
                        <p className='destination-sub-head mt-5'>VISA formalities for Foreign Nationals</p>
                        <p className='destination-description'>Please <a href='https://indianvisaonline.gov.in/evisa/tvoa.html' target='__blank'>Click</a> and check the procedure for
                        your respective Country.</p>
                        <p className='destination-sub-head mt-5'>Tourism</p>
                        <p className='destination-description'>Kerala has a rich cultural heritage with influences from various traditions and communities.
                        It is famous for its classical dance forms like Kathakali and Mohiniyattam, as well as its
                        vibrant festivals such as Onam and Vishu.</p>
                        <p className='destination-description mt-3'>Kerala is renowned for its diverse and picturesque tourist locations. Here are some must-
                        visit places in the state:</p>
                        <p className='destination-sub-head'>1. Backwaters</p>
                        <p className='destination-description'><b>Alleppey (Alappuzha):</b> Famous for its houseboat cruises along the serene backwaters,
                        with lush greenery and traditional Kerala villages.</p>
                        <p className='destination-description'><b>Kumarakom:</b> Another popular backwater destination with houseboat experiences and bird
                        sanctuaries.</p>
                        <p className='destination-sub-head'>2. Hill Stations</p>
                        <p className='destination-description'><b>Munnar:</b> Known for its tea gardens, rolling hills, and cool climate. Popular spots include
                        the Eravikulam National Park, Anamudi Peak, and Mattupetty Dam.</p>
                        <p className='destination-description'><b>Wayanad:</b> Offers beautiful landscapes, waterfalls, and wildlife sanctuaries. Key attractions
                        include Edakkal Caves, Pookode Lake, and the Wayanad Wildlife Sanctuary.</p>
                        <p className='destination-sub-head'>3. Beaches</p>
                        <p className='destination-description'><b>Kovalam:</b> Famous for its crescent-shaped beaches like Lighthouse Beach and Hawa
                        Beach, and its vibrant beach scene.</p>
                        <p className='destination-description'><b>Varkala:</b> Known for its cliff-top views and Papanasam Beach, which is believed to have
                        sacred significance.</p>
                        <p className='destination-description'><b>Cherai Beach:</b> Located near Kochi, it’s a quieter beach with a charming atmosphere and
                        good for swimming.</p>
                        <p className='destination-sub-head'>4. Wildlife Sanctuaries</p>
                        <p className='destination-description'><b>Periyar Wildlife Sanctuary:</b> Located in Thekkady, this sanctuary is known for its
                        elephants, tigers, and picturesque lake. You can enjoy boat rides and nature walks.</p>
                        <p className='destination-description'><b>Nanda Devi National Park:</b> A bit farther afield, it’s known for its high-altitude flora and
                        fauna.</p>
                        <p className='destination-sub-head'>5. Cultural and Historical Sites</p>
                        <p className='destination-description'><b>Fort Kochi:</b> A historic area with colonial architecture, Chinese fishing nets, and St. Francis
                        Church. The Kochi-Muziris Biennale is also held here.</p>
                        <p className='destination-description'><b>Padmanabhaswamy Temple:</b> Located in Thiruvananthapuram, this temple is famous for
                        its architectural grandeur and historical significance.</p>
                        <p className='destination-sub-head'>6. Ayurvedic Retreats</p>
                        <p className='destination-description'><b>Kumarakom and Varkala:</b> Both are renowned for their Ayurvedic wellness resorts and
                        treatments.</p>
                        <p className='destination-sub-head'>7. Hill and Tea Gardens</p>
                        <p className='destination-description'><b>Nelliampathi:</b> Less crowded than Munnar, it offers beautiful views and pleasant weather.</p>
                        <p className='destination-sub-head'>8. Adventure Activities</p>
                        <p className='destination-description'><b>Athirappilly:</b> Known as the &quot;Niagara of India,&quot; this waterfall is one of the largest in Kerala
                        and offers trekking and nature walks.</p>
                        <p className='destination-sub-head'>9. Local Markets and Crafts</p>
                        <p className='destination-description'><b>Kochi’s Spice Markets:</b> Explore the bustling markets for spices, textiles, and crafts.</p>
                        <p className='destination-description'><b>Kalamandalam:</b> The Kerala Kalamandalam in Thrissur is a center for learning traditional
                        performing arts.</p>
                        <p className='destination-description'>These destinations reflect the rich diversity of Kerala’s natural beauty and cultural heritage,
                        making it a fascinating place to explore.</p>
                        </div>
                    </div>
                </div>
                <p className='destination-package-head mb-3 mt-5'>View Our Kerala Packages</p>
                    <div className="destinations-container mb-5">
                    <div className="best-package-list">
                    {keralaPackages.map((pkg) => (
                        <div key={pkg.id} className="package-list-card mb-3">
                        <img
                            src={pkg.bannerImageUrl}
                            alt={pkg.destinationName}
                            className="list-card-img mb-3"
                        />
                        <div>
                            <h4 className="title-list mb-3">{pkg.destinationHead}</h4>
                            <h5 className="title-dates-list bold mb-3">{pkg.noOfDays}</h5>
                            <h5 className="title-rate-list bold mb-3">{pkg.cost}</h5>
                            <div className="btn-container-list-card">
                            <Link
                                to={`/package/${pkg.destinationName.replace(/\s+/g, "-")}`}
                                className="blogs-link"
                            >
                                <button className="view-button-list-card">
                                View Itinerary
                                </button>
                            </Link>
                            <Link to="/form" className="blogs-link">
                                <button className="view-button-list-card-book">
                                Book Now
                                </button>
                            </Link>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
            </div>
            <Footer/>
        </div>
    );
}

export default DestinationKerala;
