
import React from 'react';
import { FaWhatsapp } from 'react-icons/fa'; 

const WhatsAppButton = () => {
  const handleClick = () => {
    const message = "Can you please help me for our travel!";
    const phoneNumber = "8792273808";
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '30px',
        left: '20px',
        zIndex: 1000,
      }}
    >
      <button
        aria-label="Send message"
        onClick={handleClick}
        style={{
          backgroundColor: '#25D366',
          border: 'none',
          height:'50px',
          width:'50px',
          borderRadius: '50%',
          padding: '10px',
          color: 'white',
          fontSize: '24px',
          cursor: 'pointer',
          boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
          display:'flex',
          justifyContent:'center',
          alignItems:'center'
        }}
      >
        <FaWhatsapp size={40}/>
      </button>
    </div>
  );
};

export default WhatsAppButton;
